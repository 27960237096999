import React from 'react';
import {Text, Dropdown} from "@nextui-org/react";
import { FaCreditCard, FaCcVisa, FaCcMastercard } from "react-icons/fa6";

const getCardIcon = (brand) => {
    if (!brand) return null;

    switch (brand.toLowerCase()) {
        case 'visa':
            return <FaCcVisa size={18} />;
        case 'mastercard':
            return <FaCcMastercard size={18} />;
        default:
            return <FaCreditCard size={18} />;
    }
};

const CardDropdown = ({ savedCards, selectedCard, onSelectCard, disabled = false, allowNewCard = true }) => {
    const selectedCardData = savedCards.find(card => card.stripe_payment_method_id === selectedCard);

    const handleSelectionChange = (keys) => {
        const selected = Array.from(keys)[0];
        if (selected === 'new_card' && allowNewCard) {
            onSelectCard(null);
        } else if (selected !== selectedCard || !allowNewCard) {
            onSelectCard(selected);
        }
    };

    return (
        <Dropdown>
            <Dropdown.Button
                flat
                disabled={disabled}
                css={{
                    width: '100%',
                    justifyContent: 'space-between',
                    backgroundColor: '$accents0',
                    borderRadius: '$lg',
                    padding: '$4',
                    height: 'auto',
                    '&:hover': {
                        backgroundColor: '$accents1'
                    }
                }}
            >
                {selectedCardData ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text css={{ mr: '$2', display: 'flex', alignItems: 'center' }}>
                            {getCardIcon(selectedCardData.card_brand)}
                        </Text>
                        <Text b>
                            **** **** **** {selectedCardData.last_four}
                        </Text>
                        {selectedCardData.is_default && (
                            <Text span size="sm" color="primary" css={{ ml: '$2' }}>
                                (Default)
                            </Text>
                        )}
                    </div>
                ) : (
                    allowNewCard ? "Choose a saved card or use a new one" : "Choose a saved card"
                )}
            </Dropdown.Button>
            <Dropdown.Menu
                aria-label="Saved Cards"
                css={{ minWidth: "340px" }}
                selectionMode="single"
                selectedKeys={selectedCard ? [selectedCard] : []}
                onSelectionChange={handleSelectionChange}
            >
                {allowNewCard && (
                    <Dropdown.Item
                        key="new_card"
                        css={{
                            height: "auto",
                            py: "$2",
                            '&[data-selected=true]': {
                                backgroundColor: '$primary',
                                color: '$white',
                            }
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Text css={{ mr: '$4', display: 'flex', alignItems: 'center' }}>
                                <FaCreditCard size={18} />
                            </Text>
                            <Text>Use a new card</Text>
                        </div>
                    </Dropdown.Item>
                )}
                {savedCards.map((card) => (
                    <Dropdown.Item
                        key={card.stripe_payment_method_id}
                        css={{
                            height: "auto",
                            py: "$2",
                            '&[data-selected=true]': {
                                backgroundColor: '$primary',
                                color: '$white',
                            }
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'baseline', width: '100%', justifyContent: 'start' }}>
                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                <Text css={{ mr: '$4', display: 'flex', alignItems: 'baseline' }}>
                                    {getCardIcon(card.card_brand)}
                                </Text>
                                <Text b>**** **** **** {card.last_four}</Text>
                            </div>
                            {card.is_default && (
                                <Text span size="sm" style={{"paddingLeft": "8px"}}>
                                    (Default)
                                </Text>
                            )}
                        </div>
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default CardDropdown;