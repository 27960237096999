import React, {useEffect, useState} from 'react';
import {ResponsiveLine} from '@nivo/line';
import {ResponsiveBar} from '@nivo/bar';
import {Row, Text} from "@nextui-org/react";

const MAX_REQUESTS_PER_POD = 300;
const workloadData = [
    {
        id: "workload",
        data: [
            {x: "0", y: 0},
            {x: "1", y: 50},
            {x: "2", y: 400},
            {x: "3", y: 1000},
            {x: "4", y: 3000},
            {x: "5", y: 7000},
            {x: "6", y: 5000},
            {x: "7", y: 1000},
            {x: "8", y: 1000},
            {x: "9", y: 1000},
            {x: "10", y: 0},
        ],
    },
];

const podData = workloadData[0].data.map(item => ({
    x: item.x,
    y: item.y ? Math.max(1, Math.ceil(item.y / MAX_REQUESTS_PER_POD)) : 0,
}));


const combinedPodData = [
    {
        id: "pods",
        data: podData,
    },
];

const CustomTooltip = ({value, color}) => (
    <div style={{
        backgroundColor: "white",
        borderWidth: 1,
        borderColor: color,
        borderStyle: "solid",
        padding: 12,
        zIndex: 12,
        borderRadius: "8px"
    }}>
        <Text>GPUs: {value}</Text>
    </div>
);

const desktopLineLegend = [
    {
        anchor: 'bottom-right',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 55,
        itemsSpacing: 2,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
    }
];

const desktopOptimizedBarLegend = [
    {
        anchor: 'bottom-right',
        direction: 'row',
        justify: false,
        translateX: -450,
        translateY: 55,
        itemsSpacing: 2,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'square',
    }
];


const desktopDefaultBarLegend = [
    {
        anchor: 'bottom-right',
        direction: 'row',
        justify: false,
        translateX: -155,
        translateY: 55,
        itemsSpacing: 2,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'square',
    }
]
const mobileLineLegend = [
    {
        anchor: 'bottom-left',
        direction: 'row',
        justify: false,
        translateX: -60,
        translateY: 55,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 150,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
    }
];

const mobileOptimizedBarLegend = [
    {
        anchor: 'bottom-left',
        direction: 'row',
        justify: false,
        translateX: -60,
        translateY: 70,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 150,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'square',
    }
];

const mobileDefaultBarLegend = [
    {
        anchor: 'bottom-left',
        direction: 'row',
        translateX: -60,
        translateY: 85,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 150,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'square',
    }
];

const desktopMargin = {top: 20, right: 10, bottom: 55, left: 70};
const mobileMargin = {top: 20, right: 10, bottom: 85, left: 70};


const AutoscalerChart = () => {
    const [barLabelSkipWidth, setBarLabelSkipWidth] = useState(12);
    const [isMobile, setIsMobile] = useState(false);
    const axisBottom = {
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Time',
        legendOffset: 30,
        legendPosition: 'middle'
    };
    const [filteredData, setFilteredData] = useState({
        workloadData: workloadData,
        podData: combinedPodData,
    });

    const filterDataForScreenSize = (data, screenWidth) => {
        if (screenWidth < 600) {
            return data.filter((_, index) => index % 2 === 0);
        } else if (screenWidth < 900) {
            return data.filter((_, index) => index % 3 !== 1);
        }
        return data;
    };


    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const newWorkloadData = filterDataForScreenSize(workloadData[0].data, screenWidth);
            const newPodData = filterDataForScreenSize(podData, screenWidth);
            const skipWidth = screenWidth < 600 ? Number.MAX_VALUE : 12; // Пропускаем метки на мобильных устройствах
            setIsMobile(screenWidth < 600)
            setFilteredData({
                workloadData: [{id: "workload", data: newWorkloadData}],
                podData: [{id: "pods", data: newPodData}],
            });
            setBarLabelSkipWidth(skipWidth);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <div>
                <Row justify={"space-between"} style={{alignItems: "flex-start", zIndex: 13}}>
                    <div></div>
                    <div style={{textAlign: 'right'}}>
                        <Text h1 color="#ff70a6" size={30} css={{
                            '@media(max-width: 600px)': {
                                fontSize: '24px',
                            },
                            '@media(max-width: 400px)': {
                                fontSize: '20px',
                            },
                        }}>{`Total savings: $375,800`}</Text>
                        <Text size={16}>(per 1 year)</Text>
                    </div>
                </Row>
            </div>
            <div style={{height: '20rem', position: 'relative', zIndex: 12}}>
                <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 0}}>
                    <ResponsiveLine
                        data={filteredData.workloadData}
                        margin={isMobile ? mobileMargin : desktopMargin}
                        xScale={{type: 'point'}}
                        yScale={{type: 'linear', min: 0, max: 'auto', stacked: false}}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={axisBottom}
                        axisLeft={{
                            orient: 'left',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Workload (requests per second)',
                            legendOffset: -50,
                            legendPosition: 'middle'
                        }}
                        colors={['#ff70a6']}
                        pointSize={10}
                        pointColor={{theme: 'background'}}
                        pointBorderWidth={2}
                        pointBorderColor={{from: 'serieColor'}}
                        useMesh={true}
                        enableArea={true}
                        areaOpacity={0.1}
                        curve="monotoneX"
                        legends={isMobile ? mobileLineLegend : desktopLineLegend}
                    />
                </div>
                <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 2}}>
                    <ResponsiveBar
                        data={filteredData.podData[0].data.map(item => ({
                            ...item,
                            'Servers allocated with FlyMy.AI autoscaling algorithm': item.y
                        }))}
                        keys={['Servers allocated with FlyMy.AI autoscaling algorithm']}
                        label={d => `GPUs: ${d.value}`}
                        indexBy="x"
                        margin={isMobile ? mobileMargin : desktopMargin}
                        padding={0.4}
                        colors={["rgba(174,184,254,0.82)"]}
                        tooltip={({id, value, color}) => (
                            <CustomTooltip value={value} color={color}/>
                        )}
                        colorBy="id"
                        borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={null}
                        enableGridY={false}
                        labelSkipWidth={barLabelSkipWidth}
                        labelSkipHeight={12}
                        labelTextColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        borderRadius={3}
                        legends={isMobile ? mobileOptimizedBarLegend : desktopOptimizedBarLegend}
                    />
                </div>
                <div style={{position: 'absolute', bottom: 0, left: 0, width: '100%', height: '90%', zIndex: 1}}>
                    <ResponsiveBar
                        data={filteredData.podData[0].data.map(item => ({
                            ...item,
                            'default inference pipeline': 20
                        }))}
                        keys={['default inference pipeline']}
                        label={d => `GPUs: ${d.value}`}
                        indexBy="x"
                        margin={isMobile ? mobileMargin : desktopMargin}
                        padding={0.4}
                        colors={["rgba(204,204,204,0.7)"]}
                        tooltip={({id, value, color}) => (
                            <CustomTooltip value={value} color={color}/>
                        )}
                        colorBy="id"
                        borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={null}
                        enableGridY={false}
                        labelSkipWidth={barLabelSkipWidth}
                        labelSkipHeight={12}
                        labelTextColor={{from: 'color', modifiers: [['darker', 1.9]]}}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        borderRadius={3}
                        theme={{
                            labels: {
                                text: {
                                    baselineShift: '90px',
                                }
                            }
                        }}
                        legends={isMobile ? mobileDefaultBarLegend : desktopDefaultBarLegend}
                    />
                </div>
            </div>
        </>
    );
};

export default AutoscalerChart;

