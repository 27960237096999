import {Container, Spacer, Text, Link} from "@nextui-org/react";
import {HeaderSpacer} from "../components/Header/HeaderSpacer";
import React from "react";

function Welcome() {
    return (
        <Container lg={true} justify={"center"} style={{minHeight: "calc(100vh - 190px)", marginTop: "0px"}}>
            <HeaderSpacer/>
            <Spacer y={2} />
            <Container sm>
                <Text h1 weight="bold">
                    Thank you for registration!
                </Text>
                <Spacer y={2} />
                <Text size={22}>Public access for creators will be available soon!</Text>
                <Spacer y={1} />
                <Text size={22}>
                    For now, we are open to organizations only. If you represent a company, please <Link href="https://tally.so/r/3NVg5N">contact us</Link>.
                </Text>
                <Spacer y={2} />
                <Text size={22}>
                    Join our <Link href="https://discord.gg/t6hPBpSebw">Discord community!</Link>.
                </Text>
            </Container>
        </Container>
    );
}

export default Welcome;
