import React, { useEffect, useState } from 'react';
import {
    Card,
    Checkbox,
    Input,
    Loading,
    Spacer,
    Text,
    Textarea
} from '@nextui-org/react';
import { createPredictionRequest, createStreamPredictionRequest } from "../../../../api/playground";
import { CenteredContent } from "../ProjectMainPage";
import { toastError } from "../../../../utils/toasts";
import styled from "styled-components";
import { FaBolt } from "react-icons/fa6";
import { Link as LK } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import AddFundsModal from "../../../Finances/Modals/AddFundsModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import styles from "../styles/Playground.module.css";
import FileInput from "./FileInput";
import ProjectInfoPage from '../ProjectInfo.js';
import DownloadShare from '../components/downloadshare.tsx';

import Vector912 from "../images/Vector912.svg";
import chevronUp from "../images/chevronUp.svg";
import chevronDown from "../images/chevronDown.svg";
import lighting from "../images/lighting.svg";
import lightingButton from "../images/lightingButton.svg";

export const StyledCode = styled.code`
    background-color: transparent;
    color: black;
    overflow: auto;
    white-space: pre-wrap;
    max-width: 100%;
    display: flex;
`;

const LabelComponent = ({ name, isInstantGeneratingEnabled, isLoading }) => {
    if (isInstantGeneratingEnabled && !isLoading) {
        return (
            <Text className={styles.outputLabel}>
                {name} <Spacer x={0.5} /> <FaBolt size={13} color={"#F5A524"}
                    style={{ marginTop: "3px", marginRight: "5px", fontWeight: "bold" }} />
            </Text>
        );
    } else if (isInstantGeneratingEnabled && isLoading) {
        return (
            <Text className={styles.outputLabel}>
                {name} <FaBolt size={13} color={"#F5A524"} style={{ marginLeft: "3px", marginRight: "5px" }} />
                <Loading type="points-opacity" color={'secondary'} size="sm" />
            </Text>
        );
    } else {
        return (
            <Text className={styles.outputLabel}>
                {name}
            </Text>
        );
    }
};


const InstantGeneratingSwitch = ({ isEnabled, onChange }) => (
    <div className={styles.instantGeneratingContainer}>
        <Form.Check
            reverse
            type="switch"
            id="instant-generating-switch"
            label={<><Image src={lighting} style={{ paddingRight: "5px" }} />Instant generating</>}
            checked={isEnabled}
            onChange={onChange}
        />
    </div>
);


const isObjectEmptyOrFirstPropEmpty = (obj) => {
    const keys = Object.keys(obj);
    if (keys.length === 0) {
        return true;
    }
    const firstKey = keys[0];
    return obj[firstKey] === '';
};

const hasStreamingOutput = (outputModel) => {
    return Object.values(outputModel.properties).some(property => property.isStream === true);
};

const Playground = ({
    username,
    projectName,
    projectInfo,
    availableHardware,
    description = "",
    apiKey = "",
    inputModel,
    outputModel,
    isProjectOwner,
    requestUser,
    setRequestUser
}) => {
    const [formData, setFormData] = useState({});
    const [responseData, setResponseData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [responseError, setResponseError] = useState(null);
    const [debounceTimer, setDebounceTimer] = useState(null);
    const [isInstantGeneratingEnabled, setIsInstantGeneratingEnabled] = useState(false);
    const [showOptionalFields, setShowOptionalFields] = useState(false);
    const [inferenceTime, setInferenceTime] = useState(null);
    const [isAddFundsModalOpen, setIsAddFundsModalOpen] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const [streamDetails, setStreamDetails] = useState(null);


    useEffect(() => {
        if (inputModel && outputModel) {
            setInitialFormData();
            setInitialResponseData();
            setIsStreaming(hasStreamingOutput(outputModel));
        }
    }, [inputModel, outputModel]);

    useEffect(() => {
        const sendFormDataIfNeeded = async () => {
            await sendFormData(formData, formErrors);
        };
        const isResponseEmpty = isObjectEmptyOrFirstPropEmpty(responseData);
        if (isInstantGeneratingEnabled && formData && isResponseEmpty) {
            sendFormDataIfNeeded();
        }
    }, [isInstantGeneratingEnabled]);

    const openAddFundsModal = () => {
        setIsAddFundsModalOpen(true);
    };

    const closeAddFundsModal = async () => {
        setIsAddFundsModalOpen(false);
    }

    const onDrop = (acceptedFiles, name) => {
        const file = acceptedFiles[0];
        if (file) {
            handleChange(name, file, "file")
        }
    };

    const renderMediaContent = (name, value, type) => {
        if (!value) {
            return null;
        }
        if (type === "image/*") {
            return value.map((base64, index) => (
                <div key={`${name}-${index}`} style={{ border: "none" }} className={styles.outputMediaWrapper}>
                    <img src={`data:image/jpeg;base64,${base64}`} alt={name}
                        style={{ width: '100%' }} />
                </div>
            ));
        } else if (type === "audio/*") {
            return value.map((base64, index) => (
                <div key={`${name}-${index}`} className={styles.outputMediaWrapper}>
                    <audio controls>
                        <source src={`data:audio/wav;base64,${base64}`} type={"audio/wav"} />
                        Your browser does not support the audio element.
                    </audio>
                </div>
            ));
        } else if (type === "video/*") {
            return value.map((base64, index) => (
                <div key={`${name}-${index}`} className={styles.outputMediaWrapper}>
                    <img src={`data:image/*;base64,${base64}`} alt={name} style={{ maxWidth: '100%' }} />
                </div>
            ));
        }
    };

    const setInitialFormData = () => {
        const initialFormData = {};
        const properties = inputModel.properties;
        for (const name in properties) {
            const property = properties[name];
            if (property.type === 'boolean') {
                initialFormData[name] = property.default === true;
            } else {
                initialFormData[name] = property.default ?? '';
            }
            setFormErrors(prevFormErrors => ({
                ...prevFormErrors,
                [name]: validateValue(property.type, initialFormData[name], property)
            }));
        }
        setFormData(initialFormData);
    }

    const setInitialResponseData = () => {
        const initialResponseData = {};
        const properties = outputModel.properties;
        for (const name in properties) {
            initialResponseData[name] = '';
        }
        setResponseData(initialResponseData);
    }

    const validateValue = (type, value, { minimum, maximum, minLength, maxLength, optional }) => {
        if (optional && (value === '' || value === null || value === undefined)) {
            return '';
        }
        if (!optional && (value === '' || value === null || value === undefined)) {
            return 'This field is required.';
        }

        switch (type) {
            case 'integer':
            case 'number':
                if (value !== '') {
                    const numberValue = Number(value);
                    if (Number.isNaN(numberValue)) {
                        return "Value must be a number.";
                    }
                    if (minimum !== undefined && minimum !== null && numberValue < minimum) {
                        return `Value must be no less than ${minimum}.`;
                    }
                    if (maximum !== undefined && maximum !== null && numberValue > maximum) {
                        return `Value must be no greater than ${maximum}.`;
                    }
                }
                break;
            case 'string':
                if (value.trim()) {
                    if (minLength !== undefined && value.length < minLength) {
                        return `Length must be at least ${minLength} characters.`;
                    }
                    if (maxLength !== undefined && value.length > maxLength) {
                        return `Length must be no more than ${maxLength} characters.`;
                    }
                }
                break;
            case 'file':
                break
        }
        return '';
    };

    const isFormValid = (formData, formErrors) => {
        if (!inputModel) {
            return false;
        }
        return Object.values(formErrors).every(error => !error) &&
            Object.keys(inputModel.properties)
                .every(field => {
                    const isOptional = inputModel.properties[field].optional;
                    return isOptional || (formData[field] !== undefined && formData[field] !== '');
                });
    };

    const handleStreamingResponse = async (response) => {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = '';

        try {
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                buffer += decoder.decode(value, { stream: true });
                const lines = buffer.split('\n');

                for (let i = 0; i < lines.length - 1; i++) {
                    const line = lines[i].trim();
                    if (line.startsWith('data: ')) {
                        try {
                            const jsonString = line.slice(6);
                            const parsedData = JSON.parse(jsonString);
                            if (parsedData.detail && parsedData.status_code) {
                                throw new Error(`Error ${parsedData.status_code}: ${parsedData.detail}`);
                            }
                            if (parsedData.output_data && parsedData.output_data.detail && parsedData.status) {
                                throw new Error(`Error ${parsedData.status}: ${parsedData.output_data.detail}`);
                            }
                            if (parsedData.output_data) {
                                setResponseData(prevData => {
                                    const newData = { ...prevData };
                                    for (const [key, value] of Object.entries(parsedData.output_data)) {
                                        if (Array.isArray(value)) {
                                            newData[key] = [...(newData[key] || []), ...value];
                                        } else {
                                            newData[key] = [...(newData[key] || []), value];
                                        }
                                    }
                                    return newData;
                                });
                            }
                            if (parsedData.stream_details) {
                                setStreamDetails(parsedData.stream_details);
                            }
                        } catch (error) {
                            throw error;
                        }
                    }
                }
                buffer = lines[lines.length - 1];
            }
        } catch (error) {
            console.error("Error in stream processing:", error);
            setResponseError(error.message || "Unknown error occurred");
        }
    };

    const updateResponseData = (newData) => {
        setResponseData(prevData => {
            const updatedData = { ...prevData };
            for (const [key, value] of Object.entries(newData)) {
                if (Array.isArray(value)) {
                    updatedData[key] = [...(updatedData[key] || []), ...value];
                } else {
                    updatedData[key] = [...(updatedData[key] || []), value];
                }
            }
            return updatedData;
        });
    };

    const parseSSEResponse = (response) => {
        const lines = response.split('\n');
        for (const line of lines) {
            if (line.startsWith('data: ')) {
                const jsonString = line.slice(6);
                try {
                    return JSON.parse(jsonString);
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                    return null;
                }
            }
        }
        return null;
    };

    const sendDataToApi = async (data) => {
        setIsLoading(true);
        setResponseError(null);
        setResponseData({});
        setInferenceTime(null);
        setStreamDetails(null);

        try {
            if (isStreaming) {
                const response = await createStreamPredictionRequest(username, projectName, apiKey, data);
                await handleStreamingResponse(response);
            } else {
                const response = await createPredictionRequest(username, projectName, apiKey, data);
                const parsedResponse = parseSSEResponse(response);
                if (parsedResponse.output_data) {
                    if (parsedResponse.output_data.status && parsedResponse.output_data.status !== 200) {
                        setResponseError(parsedResponse.output_data.detail);
                    }
                    setResponseData(parsedResponse.output_data);
                } else if (parsedResponse.detail) {
                    setResponseError(parsedResponse.detail);
                } else if (parsedResponse.status_code) {
                    setResponseError(`Error ${parsedResponse.status_code}: ${parsedResponse.detail}`);
                }
                if (parsedResponse.inference_time !== null && parsedResponse.inference_time !== undefined) {
                    setInferenceTime(parseFloat((parsedResponse.inference_time * 1000).toFixed(1)));
                } else {
                    setInferenceTime(null);
                }
            }
        } catch (error) {
            console.error("API request error:", error);
            setResponseError(error.message || error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (name, value, type, constraints = {}) => {
        let updatedValue = value;
        if (type === 'boolean') {
            updatedValue = value === true;
        } else if (type === 'file') {
            updatedValue = value;
        }
        let updatedFormData = { ...formData, [name]: updatedValue };
        let updatedFormErrors = { ...formErrors, [name]: validateValue(type, updatedValue, constraints) };
        setFormData(updatedFormData);
        setFormErrors(updatedFormErrors);

        if (isInstantGeneratingEnabled) {
            clearTimeout(debounceTimer);
            const timer = setTimeout(async () => {
                await sendFormData(updatedFormData, updatedFormErrors);
            }, 300);
            setDebounceTimer(timer);
        }
    };

    const sendFormData = async (formData, formErrors) => {
        if (isFormValid(formData, formErrors)) {
            await sendDataToApi(formData);
        } else {
            if (!isInstantGeneratingEnabled) {
                toastError('Form is not valid', '42');
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await sendFormData(formData, formErrors);
    };

    const toggleOptionalFields = () => {
        setShowOptionalFields(!showOptionalFields);
    };
    return (
        <Container fluid>
            <Row className={styles.main}>
                <Col lg={6} className={styles.state}>
                    {description && (
                        <Row>
                            <Col className={styles.description}>
                                {description}
                            </Col>
                            <Spacer y={1.5} />
                        </Row>
                    )}
                    <Row>
                        <Col>
                            {inputModel && Object.entries(inputModel.properties).map(([name, detail], index) => {
                                if (detail.optional && !showOptionalFields) return null;
                                const {
                                    type,
                                    title,
                                    ...constraints
                                } = detail.type ? detail : detail.anyOf ? detail.anyOf[0] : {};

                                return (
                                    <React.Fragment key={name}>
                                        <div className={styles.inputContainer}>
                                            <div className={styles.labelContainer}>
                                                <label className={styles.inputLabel}>
                                                    {name + (!detail.optional ? ' *' : '')}
                                                </label>
                                                {index === 0 && (
                                                    <div className={styles.switchContainer}>
                                                        <InstantGeneratingSwitch
                                                            isEnabled={isInstantGeneratingEnabled}
                                                            onChange={() => setIsInstantGeneratingEnabled(!isInstantGeneratingEnabled)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className={styles.inputWrapper}>
                                                {type === 'string' && (
                                                    <Textarea
                                                        disabled={isLoading && !isInstantGeneratingEnabled}
                                                        borderWeight={"normal"}
                                                        bordered={true}
                                                        fullWidth={true}
                                                        style={{ width: '100%' }}
                                                        value={formData[name] ?? ''}
                                                        helperText={formErrors[name] || ''}
                                                        status={formErrors[name] ? 'error' : 'default'}
                                                        onChange={(e) => handleChange(name, e.target.value, type, detail)}
                                                    />
                                                )}
                                                {(type === 'integer' || type === 'number') && (
                                                    <Input
                                                        disabled={isLoading && !isInstantGeneratingEnabled}
                                                        borderWeight={"normal"}
                                                        bordered={true}
                                                        fullWidth={true}
                                                        style={{ width: '100%', borderWidth: "2px" }}
                                                        type="number"
                                                        value={formData[name] ? formData[name] : ''}
                                                        helperText={formErrors[name] || ''}
                                                        status={formErrors[name] ? 'error' : 'default'}
                                                        onChange={(e) => handleChange(name, e.target.value, type, detail)}
                                                    />
                                                )}
                                                {type === 'boolean' && (
                                                    <Checkbox
                                                        shadow
                                                        disabled={isLoading && !isInstantGeneratingEnabled}
                                                        borderWeight={"normal"}
                                                        fullWidth={true}
                                                        style={{ width: '100%', borderWidth: "2px" }}
                                                        size={'sm'}
                                                        isSelected={formData[name] === true}
                                                        onChange={(checked) => handleChange(name, checked, type, detail)}
                                                    >
                                                        {name + (!detail.optional ? ' *' : '')}
                                                    </Checkbox>
                                                )}
                                                {type === 'file' && (
                                                    <FileInput
                                                        name={name}
                                                        detail={detail}
                                                        handleChange={handleChange}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <Spacer y={1} />
                                    </React.Fragment>
                                );
                            })}
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", marginBottom: "15px" }}>
                    <Col onClick={toggleOptionalFields} style={{ display: "flex", justifyContent: "space-between" }}>
                            <span className={styles.name}>
                                Optional settings
                            </span>
                            <span>
                                <Image src={Vector912} alt="vector" style={{ paddingLeft: "5px" }} />
                            </span>
                            <span className={styles.showSetting}>
                                Show {showOptionalFields ? "less" : "more"}
                                <Image src={showOptionalFields ? chevronUp : chevronDown} alt="chevron"
                                    style={{ paddingLeft: "2px" }} />
                            </span>
                        </Col>
                    </Row>
                    {/* Buttons */}
                    <Row>
                        <Col className={`${styles.btnContainer} text-center`}>
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                {requestUser && requestUser.wallet > 0 ? (
                                    <div className={styles.runButtonDiv}>
                                        <div style={{ width: "8px" }}></div>
                                        <button
                                            className={styles.button}
                                            type="submit"
                                            disabled={isInstantGeneratingEnabled || !isFormValid(formData, formErrors) || Object.values(formErrors).some(error => error) || isLoading}
                                        >
                                            Run <Image src={lightingButton} alt="lightingButton" />
                                        </button>
                                    </div>
                                ) : requestUser && !apiKey ? (
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ lineHeight: "56px" }}>
                                            Please, generate API Key in profile settings
                                        </div>
                                        <LK
                                            style={{ lineHeight: "56px", textDecoration: "none" }}
                                            className={styles.button}
                                            to={"/profile"}
                                        >
                                            Settings <Image src={lightingButton} alt="lightingButton" />
                                        </LK>
                                    </div>
                                ) : requestUser && requestUser.wallet <= 0 ? (
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <button
                                            className={styles.button}
                                            style={{ width: "100%" }}
                                            onClick={openAddFundsModal}
                                        >
                                            Please, top up your wallet to
                                            run this model <Image src={lightingButton} alt="lightingButton" />
                                        </button>
                                    </div>
                                ) :
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ lineHeight: "56px" }}>
                                            Please, login to run this model
                                        </div>
                                        <LK
                                            style={{ lineHeight: "56px", textDecoration: "none" }}
                                            className={styles.button}
                                            to={"/login"}
                                        >
                                            Login <Image src={lightingButton} alt="lightingButton" />
                                        </LK>
                                    </div>
                                }
                            </form>
                        </Col>
                    </Row>

                    {/*<ProjectInfoPage
                        projectInfo={projectInfo}
                        availableHardware={availableHardware}
                    />*/}
                </Col>
                {/* Result colum */}
                <Col lg={6} className={styles.res}>
                    <div className={styles.resultContainer} >
                        {responseError ? (
                            <div style={{ width: "100%", minHeight: "20px", }}>
                                <Text>
                                    Inference error
                                </Text>
                                <Card variant="bordered" style={{ borderWidth: "2px" }} color={"error"}>
                                    <Card.Body>
                                        <StyledCode>{JSON.stringify(responseError, null, 2)}</StyledCode>
                                    </Card.Body>
                                </Card>
                            </div>

                        ) : (
                            outputModel && Object.entries(outputModel.properties).map(([name, detail]) => {
                                const {
                                    type,
                                    title,
                                    contentMediaType,
                                    ...constraints
                                } = detail.type ? detail : detail.anyOf ? detail.anyOf[0] : {};
                                switch (type) {
                                    case 'string':
                                    case 'integer':
                                    case 'number':
                                        return (
                                            <div key={name} style={{ minHeight: "20px" }}>
                                                <LabelComponent name={name}
                                                    isInstantGeneratingEnabled={isInstantGeneratingEnabled}
                                                    isLoading={isLoading && !responseData[name]} />
                                                <Card variant="bordered" style={{ borderWidth: "2px" }}>
                                                    <Card.Body style={{ padding: "4px" }}
                                                        className={styles.resultContainer}>
                                                        {responseData[name] && responseData[name].length > 0 ? (
                                                            type === 'string' ? (
                                                                <ReactMarkdown>{responseData[name].join('')}</ReactMarkdown>
                                                            ) : (
                                                                <Text>{responseData[name].join('')}</Text>
                                                            )
                                                        ) : isLoading ? (
                                                            <CenteredContent>
                                                                <Text h4>Running...</Text>
                                                            </CenteredContent>
                                                        ) : (
                                                            <CenteredContent>
                                                                <Text h4 color={'gray'}>Result</Text>
                                                            </CenteredContent>
                                                        )}
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        );
                                    case 'boolean':
                                        return (
                                            <>
                                                <LabelComponent name={name}
                                                    isInstantGeneratingEnabled={isInstantGeneratingEnabled}
                                                    isLoading={isLoading} />
                                                <Card>
                                                    <Card.Body>
                                                        <Text>{responseData[name] ? 'true' : 'false'}</Text>
                                                    </Card.Body>
                                                </Card>
                                                <Spacer y={1} />
                                            </>
                                        );
                                    case 'file':
                                        return (
                                            <div className={styles.result}>
                                                <LabelComponent name={name}
                                                    isInstantGeneratingEnabled={isInstantGeneratingEnabled}
                                                    isLoading={isLoading} />
                                                {isLoading && !isInstantGeneratingEnabled ? (
                                                    <CenteredContent>
                                                        <Text h4 style={{
                                                            marginBottom: "23px",
                                                            marginTop: "23px"
                                                        }}>Running...</Text>
                                                    </CenteredContent>
                                                ) : responseData[name] ? (
                                                    <>
                                                        {renderMediaContent(name, responseData[name], contentMediaType)}
                                                        <DownloadShare value={responseData[name]} type={contentMediaType} />
                                                    </>
                                                ) : (
                                                    <CenteredContent>
                                                        <Text h4 color={'gray'} style={{
                                                            marginBottom: "23px",
                                                            marginTop: "23px"
                                                        }}>Result</Text>
                                                    </CenteredContent>
                                                )}
                                            </div>
                                        );
                                    default:
                                        return null;
                                }
                            })
                        )}

                        {streamDetails && (
                            <div style={{ padding: "12px 0px 12px 0px", fontSize: "14px"}}>
                                <div style={{border: "none", padding: 0}}>
                                    <span style={{ color: "gray", lineHeight: "24px" }}>
                                        Input tokens: &nbsp;
                                    </span>
                                    <span style={{ color: "black", fontWeight: "bold" }}>
                                        {streamDetails.input_tokens || 11}
                                    </span>
                                </div>
                                <div style={{border: "none", padding: 0}}>
                                    <span style={{ color: "gray", lineHeight: "24px" }}>
                                        Output tokens: &nbsp;
                                    </span>
                                    <span style={{ color: "black", fontWeight: "bold" }}>
                                        {streamDetails.output_tokens || 15}
                                    </span>
                                </div>

                            </div>
                        )}
                        {inferenceTime && (
                            <div style={{ padding: "12px 0px 12px 0px", fontSize: "14px", }}>
                                <span style={{ color: "gray", lineHeight: "24px" }}>
                                    Inference time: &nbsp;
                                </span>
                                <span style={{ color: "black", fontWeight: "bold" }}>
                                    {inferenceTime} ms
                                </span>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
            <Elements stripe={stripePromise}>
                <AddFundsModal
                    isOpen={isAddFundsModalOpen}
                    onClose={closeAddFundsModal}
                    user={requestUser}
                    setUser={setRequestUser}
                />
            </Elements>
        </Container>
    );
};

export default Playground;