import { Container, Row, Col, Image } from "react-bootstrap";
import styles from './Card.module.css';
import flashBgGrey from '../Home/images/flashBgGrey.svg';
import { Link as LK } from 'react-router-dom';

export default function Card({ props }) {
    return (
        <LK to={`/${props.owner}/${props.name}`} className={styles.link}>
            <Container fluid className={styles.main}>
                <Row>
                    <Col className={styles.imageContainer} xl={6}>
                        <Image src={props.cover_image ?? flashBgGrey} className={styles.image} />
                        <div className={styles.runs}>
                            Runs:
                            <span className={styles.runsValue}>
                                {props.predictions_count ?? "0"}
                            </span>
                        </div>
                    </Col>
                    <Col className={styles.desc} xl={6}>
                        <Row>
                            <Col className={styles.title}>
                                {props.name}
                            </Col>
                        </Row>
                        <Row>
                            <Col className={styles.description}>
                                {props.description}
                            </Col>
                        </Row>
                        <Row>
                            <Col className={styles.tags}>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </LK>
    );
}