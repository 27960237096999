import React, {useState} from "react";
import {
    generateDictFromSchema,
    generateImports,
    generateOutputProcessingCode,
    generateStreamProcessingCode
} from "../../utils";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {oneLight} from "react-syntax-highlighter/dist/cjs/styles/prism";
import {Button, Card, Text} from "@nextui-org/react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import styles from "./styles/ApiSectionMain.module.css";
import {toastSuccess} from "../../../../../utils/toasts";


const hasStreamingField = (outputModel) => {
    if (outputModel && outputModel.properties) {
        return Object.values(outputModel.properties).some(field => field.isStream === true);
    }
    return false;
};


const regularCodeTemplate = (username, projectName, formattedInputDict, formattedOutputDict, imports, apikey) => `
# Install the FlyMyAI Python package
# pip install flymyai
${imports}

# Set secret api key. You can find your api key in the profile settings
apikey = "${apikey}" 
# Set the model name
model = "${username}/${projectName}"
# Prepare the input data
payload = ${formattedInputDict}

# Initialize the client
fma_client = client(apikey=apikey)
try:
    response = fma_client.predict(
        model=model,
        payload=payload
    )
    # Process the output data
    ${formattedOutputDict}
except FlyMyAIPredictException as e:
    print(e)
    raise e

`.trim();


const streamingCodeTemplate = (username, projectName, formattedInputDict, formattedOutputDict, imports, apikey) => `
# Install the FlyMyAI Python package
# pip install flymyai
${imports}

# Set secret api key. You can find your api key in the profile settings
apikey = "${apikey}" 
# Set the model name
model = "${username}/${projectName}"
# Prepare the input data
payload = ${formattedInputDict}

# Initialize the client
fma_client = client(apikey=apikey)

# Create a stream iterator
try:
    stream_iterator = fma_client.stream(
        model=model,
        payload=payload
    )
    # Iterate over the stream
    for response in stream_iterator:
        ${formattedOutputDict}
except FlyMyAIPredictException as e:
    print(e)
    raise e
finally:
    print()
`.trim();

export default function PythonApiSection({ username, projectInfo, inputModel, outputModel, apikey }) {
    const [showApiKey, setShowApiKey] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const isStreaming = hasStreamingField(outputModel);
    const imports = generateImports(inputModel, outputModel);
    const formattedInputDict = generateDictFromSchema(inputModel);
    const formattedOutputDict = isStreaming ? generateStreamProcessingCode(outputModel) : generateOutputProcessingCode(outputModel);
    const handleCopy = () => {
        toastSuccess("Code copied to clipboard", "Copied");
    };
    const toggleApiKey = () => {
        setShowApiKey(!showApiKey);
    };
    const displayedApiKey = showApiKey ? apikey : "fly-***";
    const pythonCode = isStreaming
        ? streamingCodeTemplate(username, projectInfo.name, formattedInputDict, formattedOutputDict, imports, displayedApiKey)
        : regularCodeTemplate(username, projectInfo.name, formattedInputDict, formattedOutputDict, imports, displayedApiKey);
    const customStyle = {
        ...oneLight,
        hljs: {
            ...oneLight.hljs,
            background: '#F6F7F8',
            fontFamily: "sans-serif",
        }
    };

    return (
            <div className={styles.codeBlock}>
                <SyntaxHighlighter language="python"
                                   style={customStyle} wrapLongLines={true}
                                   lineProps={{ style: { backgroundColor: 'transparent' } }}
                                   codeTagProps={{ style: { backgroundColor: 'transparent' } }}>
                    {pythonCode}
                </SyntaxHighlighter>
                <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', gap: '10px' }}>
                    <button className={styles.button} onClick={toggleApiKey}>
                        {showApiKey ? 'Hide API Key' : 'Show API Key'}
                    </button>
                    <CopyToClipboard text={pythonCode} onCopy={handleCopy}>
                        <button className={styles.button}>Copy</button>
                    </CopyToClipboard>
                </div>
                {isCopied ? <Text size={12} style={{ position: 'absolute', top: '35px', right: '15px', color: 'green' }}>Copied!</Text> : null}
            </div>
    );
}