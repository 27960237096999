import React, {useEffect, useState} from "react";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {addFunds, getSavedCards, saveCard} from "../../../api/finances";
import {toastSuccess} from "../../../utils/toasts";
import {Checkbox, Loading, StyledButton, Text} from "@nextui-org/react";
import CardDropdown from "./CardDropdown";
import {StyledCardElement, StyledForm, StyledInput} from "./AddFundsModal";
import {handleDRFErrors} from "./handlers";

const PaymentForm = ({ user, onSuccess }) => {
    const [amount, setAmount] = useState("");
    const [loading, setLoading] = useState(false);
    const [shouldSaveCard, setShouldSaveCard] = useState(false);
    const [savedCards, setSavedCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);
    const [cardsUpdated, setCardsUpdated] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        const fetchSavedCards = async () => {
            try {
                const cards = await getSavedCards(user.username);
                setSavedCards(cards);
                const defaultCard = cards.find(card => card.is_default);
                if (defaultCard) {
                    setSelectedCard(defaultCard.stripe_payment_method_id);
                } else if (cards.length > 0) {
                    setSelectedCard(cards[0].stripe_payment_method_id);
                }
            } catch (error) {
                console.error("Error fetching saved cards:", error);
                handleDRFErrors(error);
            }
        };
        fetchSavedCards();
    }, [user.username]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const parsedAmount = parseFloat(amount);
        if (!amount || isNaN(parsedAmount) || parsedAmount <= 0) {
            handleDRFErrors({ non_field_errors: ["Please enter a valid amount"] });
            return;
        }
        if (parsedAmount < 5) {
            handleDRFErrors({ non_field_errors: ["Minimum amount is $5"] });
            return;
        }
        setLoading(true);
        try {
            const { client_secret } = await addFunds(user.username, { amount: parsedAmount });

            let paymentMethod;
            if (selectedCard) {
                paymentMethod = selectedCard;
            } else {
                const cardElement = elements.getElement(CardElement);
                const { paymentMethod: newPaymentMethod, error } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                });
                if (error) throw error;
                paymentMethod = newPaymentMethod.id;

                if (shouldSaveCard) {
                    try {
                        await saveCard(user.username, newPaymentMethod.id);
                        toastSuccess("Card saved successfully!");
                        setCardsUpdated(true);
                    } catch (error) {
                        console.error("Error saving card:", error);
                        handleDRFErrors(error);
                    }
                }
            }

            const result = await stripe.confirmCardPayment(client_secret, {
                payment_method: paymentMethod
            });
            if (result.error) throw result.error;
            toastSuccess("Payment successful!");
            onSuccess();
        } catch (error) {
            console.error("Payment error:", error);
            handleDRFErrors(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <StyledForm onSubmit={handleSubmit}>
            <Text size={14}>
                Funds will be added to the {user.username} account and can only be used on that account.
            </Text>
            <StyledInput
                label="Amount ($)"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter amount (minimum $5)"
            />
            {savedCards && savedCards.length > 0 && (
                <CardDropdown
                    savedCards={savedCards}
                    selectedCard={selectedCard}
                    onSelectCard={setSelectedCard}
                />
            )}
            {!selectedCard && (
                <>
                    <Text size={14}>Card details:</Text>
                    <StyledCardElement options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }} />
                    <Checkbox
                        size="sm"
                        isSelected={shouldSaveCard}
                        onChange={(checked) => setShouldSaveCard(checked)}
                    >
                        Save this card for future use
                    </Checkbox>
                </>
            )}
            <StyledButton auto color="success" type="submit" disabled={loading}>
                {loading ? <Loading type="points-opacity" color="currentColor" size="sm" /> : 'Add Funds'}
            </StyledButton>
        </StyledForm>
    );
};

export default PaymentForm;