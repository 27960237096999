import React, {useEffect, useState} from 'react';
import {Modal, Text, Button, Input, Spacer} from "@nextui-org/react";
import {toastError, toastSuccess, toastWarn} from "../../../../utils/toasts";
import {checkCeleryTaskStatus} from "../../../../api/projects";
import {getNodes, testProject, updateIOField} from "../../../../api/flow";

const TestServerModal = ({isOpen, onClose, username, projectInfo, setProjectInfo,}) => {
    const [sourceHandles, setSourceHandles] = useState([]);
    const [inputNode, setInputNode] = useState(null);
    const [allFieldsFilled, setAllFieldsFilled] = useState(false);
    const [userInputValues, setUserInputValues] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});


    const handleClose = () => {
        onClose();
        document.body.style.overflow = 'auto';
    };

    const validateDims = (dims) => {
        const regex = /^\d+(,\d+)*$/;
        return regex.test(dims);
    };

    const checkAllFieldsFilled = (handles, userValues) => {
        const allFilled = handles.every((handle, index) => {
            const userInput = userValues[index];
            const hasError = validationErrors[index];
            if (hasError) {
                return false;
            }
            if (Array.isArray(userInput)) {
                return userInput.length > 0;
            } else if (typeof userInput === 'string') {
                return Boolean(userInput.trim());
            } else {
                return false;
            }
        });
        setAllFieldsFilled(allFilled);
    };



    useEffect(() => {
        let isMounted = true;

        const fetchNodes = async () => {
            if (isOpen) {
                const inputNodes = await getNodes(username, projectInfo.name, "INPUT");
                if (inputNodes.length > 0 && isMounted) {
                    const inputNode = inputNodes[0];
                    setInputNode(inputNode);
                    setSourceHandles(inputNode.source_handles || []);
                    const initialValues = inputNode.source_handles.map(handle => handle.data ? handle.data.perf_analyzer_dims : '');
                    setUserInputValues(initialValues);
                    checkAllFieldsFilled(inputNode.source_handles || [], initialValues);
                } else if (isMounted) {
                    setSourceHandles([]);
                }
            }
        };

        fetchNodes();

        return () => {
            isMounted = false;
        };
    }, [isOpen, username, projectInfo.name]);

    useEffect(() => {
        checkAllFieldsFilled(sourceHandles, userInputValues);
    }, [sourceHandles, userInputValues, validationErrors]);

    const handleDimChange = (index, value) => {
        const updatedUserInputValues = [...userInputValues];
        updatedUserInputValues[index] = value;
        setUserInputValues(updatedUserInputValues);

        const isValid = validateDims(value);
        const newValidationErrors = {...validationErrors};
        if (!isValid) {
            newValidationErrors[index] = "Only positive numbers separated by commas are allowed.";
        } else {
            delete newValidationErrors[index];
        }
        setValidationErrors(newValidationErrors);

        const updatedHandles = [...sourceHandles];
        const dims = value.split(',').map(dim => dim.trim()).filter(dim => dim);
        if (updatedHandles[index].data) {
            updatedHandles[index].data.perf_analyzer_dims = dims;
        }

        setSourceHandles(updatedHandles);
        checkAllFieldsFilled(updatedHandles, updatedUserInputValues);
    };


    const updatePerfDims = async (handle) => {
        if (handle.data && handle.data.perf_analyzer_dims) {
            try {
                await updateIOField(handle, handle.id, inputNode.id, username, projectInfo.name);
            } catch (error) {
                toastWarn("Failed to update perf_analyzer_dims");
            }
        }
    };

    const handleSubmit = async () => {
        setProjectInfo({...projectInfo, state: 'TESTING'});
        handleClose();
        await testProject(username, projectInfo.name);
    }

    return (
        isOpen ? (
            <Modal open={isOpen} onClose={handleClose}>
                <Modal.Header>
                    <Text h3>Testing pipeline performance</Text>
                </Modal.Header>
                <Modal.Body>
                    {
                        sourceHandles.length === 0 ? (
                            <Text h5>
                                No input nodes found. Please, add an input node to the pipeline to test its performance.
                            </Text>
                        ) : ['IDLE', 'ERROR'].includes(projectInfo.state) ? (
                            <>
                                <Text h5>Please, enter perf dimensions for each input</Text>
                                    {sourceHandles.map((handle, index) => (
                                            <div key={`wrapper-${index}`}>
                                                <Spacer y={0.2}/>
                                                <Input
                                                    id={`input-dims-${index}`}
                                                    aria-label={`Dimensions for ${handle.label_slug}`}
                                                    placeholder={handle.dims || 'Enter dimensions'}
                                                    labelLeft={handle.label_slug}
                                                    value={userInputValues[index] || ''}
                                                    onChange={(e) => handleDimChange(index, e.target.value)}
                                                    fullWidth={true}
                                                    helperText={validationErrors[index]}
                                                />
                                                <Spacer y={0.5}/>
                                            </div>
                                        ))}
                            </>
                        ) : (
                            <Text size={16}>Project is in {projectInfo.state} state now and cannot be tested.</Text>
                        )
                    }

                </Modal.Body>

                <Modal.Footer>
                    <Button auto flat color="error" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        auto
                        color="warning"
                        disabled={!allFieldsFilled || Object.keys(validationErrors).length > 0}
                        onClick={async () => {
                            for (const handle of sourceHandles) {
                                await updatePerfDims(handle);
                            }
                            await handleSubmit();
                        }}>
                        Test Pipeline
                    </Button>
                </Modal.Footer>
            </Modal>
        ) : null
    );
}

export default TestServerModal;
