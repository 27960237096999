import {ResponsiveBar} from "@nivo/bar";
import React from "react";


const CustomResponsiveBar = ({ data, xAxisTitle, yAxisTitle }) => {
    const colors = [
        '#aeb8fe', '#ff70a6', '#9cdbff', '#ffca3a', '#90e0ef', '#f9c74f',
    ];
    xAxisTitle = xAxisTitle || 'Accelerator';

    return (
        <ResponsiveBar
            data={data}
            keys={['value']}
            indexBy="accelerator"
            margin={{ top: 20, right: 50, bottom: 50, left: 80 }}
            padding={0.3}
            layout="vertical"
            colors={bar => colors[bar.index % colors.length]}
            axisBottom={{
                legend: xAxisTitle,
                legendPosition: 'middle',
                legendOffset: 40
            }}
            axisLeft={{
                legend: yAxisTitle,
                legendPosition: 'middle',
                legendOffset: -50
            }}
            theme={{
                axis: {
                    ticks: {
                        text: {
                            fontSize: 14
                        }
                    },
                    legend: {
                        text: {
                            fontSize: 16
                        }
                    }
                },
                labels: {
                    text: {
                        fontSize: 18
                    }
                }
            }}
        />
    );
};

export default CustomResponsiveBar;