import React from 'react';
import { Table, useAsyncList } from "@nextui-org/react";
import {getTransactions} from "../../api/finances";

function TransactionsTable({username}) {
    const columns = [
        { name: "Amount", uid: "amount" },
        { name: "Date", uid: "timestamp" },
        { name: "Description", uid: "description" },
        { name: "Transaction Type", uid: "transaction_type" },
    ];

    function formatAmount(value) {
        const isNegative = value < 0;
        const formattedValue = Math.abs(value).toFixed(4);
        return (
            <span>
            {isNegative ? '-$' : '    $'}{formattedValue}
        </span>
        );
    }

    async function load({ cursor }) {
        const page = cursor || 1;
        const response = await getTransactions(username, page);
        return {
            items: response.results,
            cursor: response.next ? response.next.split('=')[1] : null,
        };
    }

    const list = useAsyncList({ load });

    return (
        <Table
            aria-label="Transactions"
            css={{ minWidth: "100%", overflowY: "auto", height: "65vh" }}
            shadow={false}
            bordered
        >
            <Table.Header columns={columns}>
                {(column) => (
                    <Table.Column key={column.uid}>{column.name}</Table.Column>
                )}
            </Table.Header>
            <Table.Body
                items={list.items}
                loadingState={list.loadingState}
                onLoadMore={list.loadMore}
            >
                {(item) => (
                    <Table.Row key={item.id}>
                        {(key) =>
                            <Table.Cell>
                                {key === 'amount' ?
                                    formatAmount(item[key])
                                    : key === 'timestamp' ?
                                        new Date(item[key]).toLocaleString()
                                        : key === 'transaction_type' ?
                                            <span style={{
                                                color: item[key] === 'Debit' ? 'red' : 'green',
                                                fontWeight: 'bold'
                                            }}>
                                                {item[key]}
                                            </span>
                                            :
                                            item[key]
                                }
                            </Table.Cell>
                        }
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );
}

export default TransactionsTable;
