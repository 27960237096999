import React, { useEffect, useState, useRef, useCallback } from 'react';
import { getProjects } from '../../api/projects';
import { Loading } from '@nextui-org/react';
import ProjectListItem from "./ProjectListItem";
import { HeaderSpacer } from "../Header/HeaderSpacer";
import { useSelector } from "react-redux";
import ProjectSettingsModal from "../Flow/Modals/ProjectSettingsModal";
import { CenteredContent } from "./ProjectMain/ProjectMainPage";
import AdaptiveDemoListItem from "../Home/AdaptiveProjectItem";
import Card from '../Card';
import { Row, Col, Container } from 'react-bootstrap';

function ProjectList() {
    const [projects, setProjects] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();
    const loadingRef = useRef(false);
    const username = useSelector((state) => state.user.activeUser.username);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchData = async (page, username) => {
        if (loadingRef.current) return;

        setLoading(true);
        loadingRef.current = true;

        try {
            const data = await getProjects(username, page);
            setProjects(prev => [...prev, ...data.results]);
            setHasMore(Boolean(data.next));
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
            loadingRef.current = false;
        }
    };

    useEffect(() => {
        setPage(1);
        setProjects([]);
        setHasMore(true);
        fetchData(1, username);
    }, [username]);

    // useEffect(() => {
    //     if (projects.length > 0 && !projects.some(p => p.isPlaceholder)) {
    //         const placeholderProject = {
    //             id: 'placeholder',
    //             name: 'Add my model',
    //             description: 'Interested in adding your own model? Click here to submit your request!',
    //             isPlaceholder: true,
    //         };
    //         setProjects(prevProjects => [placeholderProject, ...prevProjects]);
    //     }
    // }, [projects.length]);

    useEffect(() => {
        if (page !== 1) {
            fetchData(page, username);
        }
    }, [page, username]);

    const loadMoreProjects = useCallback(() => {
        if (hasMore && !loadingRef.current) {
            setPage(prevPage => prevPage + 1);
        }
    }, [hasMore]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        };

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                loadMoreProjects();
            }
        }, options);

        return () => {
            if (observer.current) observer.current.disconnect();
        };
    }, [loadMoreProjects]);

    const lastProjectRef = useCallback(node => {
        if (observer.current) observer.current.disconnect();
        if (node) observer.current.observe(node);
    }, []);

    if (error) {
        return <div className="error">{error}</div>;
    }

    return (
        <Container fluid="lg" style={{minHeight: "calc(100vh - 190px)", marginTop: "0px"}}>
            <HeaderSpacer />
            <Row>
                <Col>
                    <h2>Projects</h2>
                </Col>
                <Col xs={3} style={{display: 'flex',justifyContent: 'flex-end'}}>
                    {/* <Button bordered color={'secondary'} auto onClick={() => setIsModalOpen(true)}>Create new project</Button> */}
                </Col>
            </Row>
            {/*<ProjectSettingsModal*/}
            {/*    isOpen={isModalOpen}*/}
            {/*    onClose={() => setIsModalOpen(false)}*/}
            {/*    mode='create'*/}
            {/*    username={username}*/}
            {/*/>*/}
            <Row style={{paddingBottom: "24px"}}>
                {projects.map((project, index) => (
                    <Col key={index} xs={12} sm={6} md={6} lg={4} xl={4}
                        ref={projects.length === index + 1 ? lastProjectRef : null}>
                        <Card props={project} />
                    </Col>
                ))}
            </Row>
            {loading && <CenteredContent><Loading color={"secondary"} /></CenteredContent>}
        </Container>
    );
}

export default ProjectList;
