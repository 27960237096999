import React, {useEffect, useState} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../../styles/tab-styles.css';
import {Container, Text, Grid, Button, Spacer, Loading} from '@nextui-org/react';
import {HeaderSpacer} from "../Header/HeaderSpacer";
import TransactionsTable from './TransactionsTable';
import PaymentsTable from './PaymentsTable';
import HardwareUsageTable from "./HardwareUsageTable";
import AddFundsModal from "./Modals/AddFundsModal";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {getUser} from "../../api/users";
import {getPayments, getTransactions} from "../../api/finances";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {updateRequestUser} from "../../store/actions/user";

function FinancesList() {
    const dispatch = useDispatch();
    const location = useLocation();
    const [isAddFundsModalOpen, setIsAddFundsModalOpen] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [payments, setPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

    const [user, setUser] = useState(null);
    const requestUser = useSelector((state) => state.user.user);
    const activeUser = useSelector((state) => state.user.activeUser);

    const getInitialActiveTabIndex = () => {
        const queryParams = new URLSearchParams(location.search);
        const tab = queryParams.get('tab');
        switch (tab) {
            case 'hardware': return 0;
            case 'transactions': return 1;
            case 'payments': return 2;
            default: return 0;
        }
    };

    const [activeTabIndex, setActiveTabIndex] = useState(getInitialActiveTabIndex);
    useEffect(() => {
        setActiveTabIndex(getInitialActiveTabIndex());
    }, [location.search]);

    const fetchTransactionsAndPayments = async () => {
        setIsLoading(true);
        try {
            const [updatedRequestUser, updatedActiveUser] = await Promise.all([
                getUser(requestUser?.username),
                getUser(activeUser?.username),
            ]);
            setUser(updatedActiveUser);

            dispatch(updateRequestUser(updatedRequestUser));

            if (updatedActiveUser) {
                const [transactionsData, paymentsData] = await Promise.all([
                    getTransactions(updatedActiveUser.username),
                    getPayments(updatedActiveUser.username)
                ]);
                setTransactions(transactionsData);
                setPayments(paymentsData);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTransactionsAndPayments();
    }, [dispatch, requestUser?.username, activeUser?.username]);

    const openAddFundsModal = () => {
        setIsAddFundsModalOpen(true);
    };

    const closeAddFundsModal = () => {
        setIsAddFundsModalOpen(false);
    };

    if (isLoading) {
        return (
            <Container css={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <Loading size="md">Loading finances data...</Loading>
            </Container>
        );
    }

    if (!user) {
        return (
            <Container lg={true}>
                <HeaderSpacer />
                <Text h2>User data not available</Text>
            </Container>
        );
    }

    return (
        <Container lg={true}>
            <HeaderSpacer/>
            <Grid.Container alignItems="center" justify="space-between">
                <Grid xs={12} sm={6}>
                    <Text h2>Finances: ${user.wallet}</Text>
                </Grid>
                <Grid xs={12} sm={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={openAddFundsModal} color="gradient" auto>
                        <Text style={{color: 'white'}}>Add funds</Text>
                    </Button>
                </Grid>
            </Grid.Container>
            <Spacer y={1} />
            <Tabs selectedIndex={activeTabIndex} onSelect={index => setActiveTabIndex(index)} style={{borderBottom: 'none'}}>
                <TabList style={{
                    borderBottom: "none",
                    padding: "0",
                    margin: "0",
                    marginBottom: "5px",
                    paddingBottom: "15px",
                    overflowY: "hidden",
                    overflowX: "auto",
                }}>
                    <Tab>Hardware Usage</Tab>
                    <Tab>Transactions</Tab>
                    <Tab>Payments</Tab>
                </TabList>
                <TabPanel style={{minHeight: "calc(100vh - 290px)", marginTop: "0px"}}>
                    <HardwareUsageTable username={user.username}/>
                </TabPanel>
                <TabPanel>
                    <TransactionsTable username={user.username} transactions={transactions}/>
                </TabPanel>
                <TabPanel>
                    <PaymentsTable username={user.username} payments={payments}/>
                </TabPanel>
            </Tabs>
            <Elements stripe={stripePromise}>
                <AddFundsModal
                    isOpen={isAddFundsModalOpen}
                    onClose={closeAddFundsModal}
                    user={user}
                    setUser={setUser}
                />
            </Elements>
        </Container>
    );
}

export default FinancesList;