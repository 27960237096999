import { Badge } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { findLabelById } from "./utils";
import SettingsModal from "./Modals/SettingsModal";
import RunServerModal from "./Modals/RunServerModal";
import StopServerModal from "./Modals/StopServerModal";
import { cloneProject } from "../../../api/projects";
import styled from "styled-components";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { FaFlask } from "react-icons/fa";
import { Row, Col, Container } from "react-bootstrap";
import styles from "./styles/ProjectHeader.module.css"
import Copy from "../Copy/Copy";
import Tags from "../Tags/Tags";
import { useResize } from "../../../hooks/useresize.ts";
import ProjectInfoPage from "./ProjectInfo.js";

const Title = styled.code`
    font-size: 1.4em;

    @media (max-width: 768px) {
        display: block;
        text-align: center;
        width: 100%;
        margin-top: 20px;
    }
`;

const LicenseBadge = styled(Badge)`
    font-size: 1em;
    padding-top: 8px;
    border-radius: 10px;
    @media (max-width: 768px) {
        align-self: center;
    }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export default function ProjectHeader({
    username,
    projectInfo,
    setProjectInfo,
    availableHardware,
    isProjectOwner,
    requestUser,
    infTimeStatistics
}) {
    const userAndProjectName = `${username}/${projectInfo.name}`
    const [acceleratorName, setAcceleratorName] = useState(null);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [showRunServerModal, setShowRunServerModal] = useState(false);
    const [showStopServerModal, setShowStopServerModal] = useState(false);
    const handleOpenSettings = () => setShowSettingsModal(true);
    const handleCloseSettings = () => setShowSettingsModal(false);
    const handleCloseRunServer = () => setShowRunServerModal(false);
    const handleCloseStopServer = () => setShowStopServerModal(false);
    const widthScreen = useResize()
    const BREAKPOINT = 991

    useEffect(() => {
        if (projectInfo) {
            fillProjectInfo();
        }
    }, [projectInfo]);


    const fillProjectInfo = () => {
        const acceleratorName = findLabelById(projectInfo.preferred_accelerator, availableHardware)
        setAcceleratorName(acceleratorName)
    }

    useEffect(() => {
        if (projectInfo) {
            fillProjectInfo();
        }
    }, [projectInfo]);

    const cloneThatProject = async () => {
        const clonedProject = await cloneProject(username, projectInfo.name, requestUser.username);
        window.location.href = `/${clonedProject.owner}/${clonedProject.name}`;
    };

    function DeployBtn({ st }) {
        return (
            <Col style={{ display: "flex", ...st }}>
                {requestUser && requestUser.group === 1 && (
                    <button className={styles.deployButton} onClick={cloneThatProject}>
                        Deploy to my account
                    </button>
                )}
            </Col>
        );
    }

    if (!projectInfo) {
        return (
            <h1>Project not found</h1>
        )
    } else {
        return (
            <>
                <Row>
                    <Col className={styles.breadCrumbs}>
                        Explore &rsaquo; {username}/{projectInfo.name}
                    </Col>
                </Row>
                <Row>
                    <Col className={styles.projectNameTitle} md={7}>
                        {userAndProjectName} <Copy text={userAndProjectName} />
                    </Col>
                    {widthScreen >= BREAKPOINT && <DeployBtn st={{ justifyContent: "flex-end", }} />}
                </Row>
                <Row>
                    <ProjectInfoPage
                        projectInfo={projectInfo}
                        availableHardware={availableHardware}
                    />
                </Row>
                <Row>
                    <Col xs={12}>
                        <Tags props={projectInfo} />
                    </Col>
                    {widthScreen < BREAKPOINT && <DeployBtn st={{ justifyContent: "flex-start", marginTop: "15px" }} />}
                </Row>
                {requestUser &&
                    <>
                        <SettingsModal
                            isOpen={showSettingsModal}
                            onClose={handleCloseSettings}
                            mode="edit"
                            username={username}
                            projectInfo={projectInfo}
                            setProjectInfo={setProjectInfo}
                            availableHardware={availableHardware}
                        />
                        <RunServerModal
                            isOpen={showRunServerModal}
                            onClose={handleCloseRunServer}
                            username={username}
                            projectInfo={projectInfo}
                            setProjectInfo={setProjectInfo}
                            availableHardware={availableHardware}
                            requestUser={requestUser}
                        />
                        <StopServerModal
                            isOpen={showStopServerModal}
                            onClose={handleCloseStopServer}
                            username={username}
                            projectInfo={projectInfo}
                            setProjectInfo={setProjectInfo}
                        />
                    </>
                }
            </>

        );
    }

}

