import { Text, Tooltip } from "@nextui-org/react";
import React from "react";
import podRunning from "./images/podRunning.svg";
import podStarting from "./images/podStarting.svg";
import podStopping from "./images/podStopping.svg";
import podIdle from "./images/Rectangle6359.svg";
import { Image } from "react-bootstrap";

const getTooltipContent = (deployment) => {
    return (
        <>
            <Text color={"white"} style={{margin: 0}} size={12}>
                State: {deployment.state}
            </Text>
            <Text color={"white"} size={12} style={{margin: 0}} >
                {deployment?.hourly_price 
                    ? `Hourly Price: ${deployment.hourly_price.toFixed(2)}`
                    : 'It will be scaled when needed'}
            </Text>
        </>
    )
};

const getImageSource = (state) => {
    switch (state) {
        case "RUNNING":
            return podRunning;
        case "STARTING":
            return podStarting;
        case "STOPPING":
            return podStopping;
        case "IDLE":
            return podIdle;
        default:
            return podIdle; // Default to idle image if state is unknown
    }
};

export default function DeploymentInfo({ deployment }) {
    return (
        <Tooltip content={getTooltipContent(deployment)} placement="topStart" hideArrow
                 color={"invert"} style={{ padding: "0px" }}>
            <Image src={getImageSource(deployment.state)} alt={`pod-${deployment.state.toLowerCase()}`} />
        </Tooltip>
    )
}