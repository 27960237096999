import React, { useEffect, useState } from 'react';
import { Grid } from '@nextui-org/react';
import { getDemos } from '../../api/demos';
import AdaptiveDemoListItem from "./AdaptiveProjectItem";
import {ProjectPlaceholder} from "./PlaceHolderCard";

const ProjectsGrid = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const data = await getDemos(1, 4);
                setProjects(data.results);
            } catch (error) {
                console.error("Error fetching projects", error);
            } finally {
                setLoading(false);
            }
        };
        fetchProjects();
    }, []);


    return (
        <Grid.Container gap={2} justify="flex-start">
            {loading ? (
                Array.from({ length: 4 }, (_, index) => (
                    <Grid key={index} xs={12} sm={6}>
                        <ProjectPlaceholder />
                    </Grid>
                ))
            ) : (
                projects.map((project) => (
                    <Grid key={project.id} xs={12} sm={6}>
                        <AdaptiveDemoListItem project={project} />
                    </Grid>
                ))
            )}
        </Grid.Container>
    );
};

export default ProjectsGrid;
