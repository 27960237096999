import styles from "./styles/ApiSectionMain.module.css";
import { Spacer, Text } from "@nextui-org/react";
import PythonApiSection from "./PythonApiSection";
import { Container, Row } from "react-bootstrap";

export default function ApiSectionMain({ username, projectInfo, inputModel, outputModel, apikey }) {

    return (
        <Container fluid>
            <Row className={styles.main}>
                <Text h2>API</Text>
                <PythonApiSection className={styles.section}
                    username={username} projectInfo={projectInfo} inputModel={inputModel}
                    outputModel={outputModel} apikey={apikey} />
            </Row>
        </Container>
    );
}