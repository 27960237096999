import React from 'react';
import {Table, useAsyncList, Link, Button} from "@nextui-org/react";
import { getPayments } from "../../api/finances";

function PaymentsTable({username}) {
    const columns = [
        { name: "Amount", uid: "amount" },
        { name: "Date", uid: "created_at" },
        { name: "Status", uid: "status" },
        { name: "Receipt", uid: "receipt_url" },
    ];

    async function load({ cursor }) {
        const page = cursor || 1;
        const data = await getPayments(username, page);
        return {
            items: data.results,
            cursor: data.next ? data.next.split('=')[1] : null,
        };
    }

    const list = useAsyncList({ load });

    const renderCell = (item, columnKey) => {
        const cellValue = item[columnKey];
        switch (columnKey) {
            case "amount":
                return `$${cellValue}`;
            case "created_at":
                return new Date(cellValue).toLocaleDateString();
            case "receipt_url":
                return cellValue ? (
                    <Link href={cellValue} target="_blank" rel="noopener noreferrer">
                        <Button auto flat size={"sm"} color="primary">Open</Button>
                    </Link>
                ) : null;
            default:
                return cellValue;
        }
    };

    return (
        <Table
            aria-label="Payments"
            css={{ minWidth: "100%", height: "65vh" }}
            shadow={false}
            bordered
        >
            <Table.Header columns={columns}>
                {(column) => (
                    <Table.Column key={column.uid}>{column.name}</Table.Column>
                )}
            </Table.Header>
            <Table.Body
                items={list.items}
                loadingState={list.loadingState}
                onLoadMore={list.loadMore}
            >
                {(item) => (
                    <Table.Row key={item.id}>
                        {(columnKey) => (
                            <Table.Cell>{renderCell(item, columnKey)}</Table.Cell>
                        )}
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );
}

export default PaymentsTable;