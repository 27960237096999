import React, { useState, useEffect } from 'react';
import { Card } from "@nextui-org/react";
import CustomResponsiveBar from "../../utils/customResponsiveBar";

const acceleratorsResultsThroughput = [
    {
        accelerator: "A100",
        value: 400.0
    },
    {
        accelerator: "A10",
        value: 196.58,
    },
    {
        accelerator: "T4",
        value: 159.32,
    }
];

const acceleratorsResultsEffectivity = [
    {
        accelerator: "A100",
        value: 1.65
    },
    {
        accelerator: "A10",
        value: 1.95
    },
    {
        accelerator: "T4",
        value: 1.0
    }
];

export const HardwareCompare = () => {
    const [displayDataThroughput, setDisplayDataThroughput] = useState(acceleratorsResultsThroughput);
    const [displayDataEffectivity, setDisplayDataEffectivity] = useState(acceleratorsResultsEffectivity);

    const filterDataForScreenSize = (data) => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 600) {
            return data.slice(0, -1);
        }
        return data;
    };

    useEffect(() => {
        const handleResize = () => {
            setDisplayDataThroughput(filterDataForScreenSize(acceleratorsResultsThroughput));
            setDisplayDataEffectivity(filterDataForScreenSize(acceleratorsResultsEffectivity));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Card>
            <Card.Body>
                <div style={{height: '15rem'}}>
                    <CustomResponsiveBar
                        data={displayDataThroughput}
                        yAxisTitle="Inferences/second"
                        xAxisTitle="GPU"
                    />
                </div>
                <div style={{height: '15rem'}}>
                    <CustomResponsiveBar
                        data={displayDataEffectivity}
                        yAxisTitle="Effectivity"
                        xAxisTitle="GPU"
                    />
                </div>
            </Card.Body>
        </Card>
    );
};
