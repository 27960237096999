import React from 'react';
import { Range, getTrackBackground } from 'react-range';

const TRACK_BG = '#f0f0f0';
const THUMB_BG = '#0F32AF';


const Slider = ({ min, max, value, onChange, step=1}) => (
    <div style={{ position: 'relative'}}>
        <Range
            values={[value]}
            step={step}
            min={min}
            max={max}
            onChange={onChange}
            renderTrack={({ props, children }) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                        height: '6px',
                        width: '100%',
                        background: getTrackBackground({
                            values: [value],
                            colors: [THUMB_BG, TRACK_BG],
                            min: min,
                            max: max,
                        }),
                    }}
                >
                    {children}
                </div>
            )}
            renderThumb={({ props }) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                        height: '16px',
                        width: '16px',
                        borderRadius: '50%',
                        backgroundColor: THUMB_BG,
                    }}
                />
            )}
        />
    </div>
);

export default Slider;
