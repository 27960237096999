import React, { useState, useRef, useEffect } from 'react';
import { Input, Button } from '@nextui-org/react';
import { FaMicrophone, FaStop } from 'react-icons/fa';
import styles from '../styles/Playground.module.css';
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

const globalRegisterPromise = connect().then(register);

const FileInput = ({ name, detail, handleChange }) => {
    const [file, setFile] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const fileInputRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    const isAudioInput = detail.contentMediaType === 'audio/*';

    useEffect(() => {
        if (isAudioInput) {
            const setupRecorder = async () => {
                if (!isReady) {
                    await globalRegisterPromise;
                    setIsReady(true);
                }
            };
            setupRecorder();
        } else {
            setIsReady(true);
        }
    }, [isAudioInput, isReady]);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            handleChange(name, selectedFile, 'file');
        }
    };

    const toggleRecording = async () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    const startRecording = async () => {
        setFile(null);
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/wav' });
            audioChunksRef.current = [];

            mediaRecorderRef.current.ondataavailable = (event) => {
                audioChunksRef.current.push(event.data);
            };

            mediaRecorderRef.current.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                const audioFile = new File([audioBlob], 'your_amazing_voice.wav', { type: 'audio/wav' });
                setFile(audioFile);
                handleChange(name, audioFile, 'file');
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
        } catch (error) {
            alert('Failed to start recording. Please check your microphone permissions.');
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    return (
        <div className={styles.fileInputContainer}>
            <input
                type="file"
                onChange={handleFileChange}
                className={styles.fakeFileInput}
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept={detail.contentMediaType}
            />
            <div className={styles.inputWrapper}>
                <Input
                    readOnly
                    placeholder="Select a file"
                    value={file ? file.name : ''}
                    className={styles.fileInput}
                    fullWidth
                />
                <button onClick={() => fileInputRef.current.click()} className={styles.chooseFileButton}>
                    Choose...
                </button>
                {isAudioInput && (
                    <Button
                        auto
                        light
                        icon={isRecording ? <FaStop size={20} /> : <FaMicrophone size={20} />}
                        onPress={toggleRecording}
                        className={styles.recordButton}
                        disabled={!isReady}
                    />
                )}
            </div>
        </div>
    );
};

export default FileInput;
