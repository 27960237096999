import {Container, Row, Col, Text, Button, Grid, Spacer} from "@nextui-org/react";
import {HeaderSpacer} from "../Header/HeaderSpacer";
import React from "react";
import {
    AiOutlineBarChart,
    AiOutlineCheckCircle
} from "react-icons/ai";
import {animated, useSpring} from 'react-spring';
import styled from "styled-components";
import AutoscalerChart from "./AutoscalerLine";
import networksImage from './images/networks.svg';
import { Link as LK } from 'react-router-dom';
import {CenteredContent} from "../Projects/ProjectMain/ProjectMainPage";
import {TiFlash} from "react-icons/ti";
import {MdOutlineAutoGraph} from "react-icons/md";
import ProjectsGrid from "./DemoGrid";
import {HardwareCompare} from "./HardwareCompare";



const CenteredGrid = styled(animated.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
`;

const AnimatedGrid = ({delay, children}) => {
    const props = useSpring({
        from: {opacity: 0, transform: 'translate3d(0, 30px, 0)'},
        to: {opacity: 1, transform: 'translate3d(0, 0, 0)'},
        config: {tension: 200, friction: 20},
        delay,
    });

    return <animated.div style={props}>{children}</animated.div>;
};



const RightOrderGrid = styled(Grid)`
    @media screen and (max-width: 650px) {
        order: 5;
    }
    @media screen and (min-width: 651px) {
        order: 5;
    }
`;

const FixedGrid = styled(Grid)`
    order: 3;
`;

function Home() {
    return (
        <Container lg={true}>
            <HeaderSpacer/>
            <Row justify="center" align="center">
                <Col>
                    <Text
                        h1
                        size={60}
                        css={{
                            textGradient: "45deg, $blue600 -20%, $pink600 50%",
                            "@xsMax": {
                                fontSize: "30px",
                            },
                            "@smMax": {
                                fontSize: "40px",
                            },
                        }}
                        weight="bold"
                    >
                        Empower Your Ideas with AI
                    </Text>
                    <Grid.Container>
                        <Grid xs={12} sm={10}>
                            <Text size={20}>
                                Our AI-powered tools help you create amazing designs, videos, and more.
                            </Text>
                        </Grid>
                        <Grid xs={12} sm={2}>
                            <Button
                                shadow color="gradient" auto
                                as={LK} to={"/explore"}
                                css={{
                                    '@smMax': {
                                        marginTop: '20px',
                                        width: '100%',
                                    },
                                }}
                            >
                                Start Your AI Journey
                            </Button>
                        </Grid>

                    </Grid.Container>
                </Col>
            </Row>
            <Spacer y={1}/>
            <ProjectsGrid/>
            <Grid.Container gap={2} css={{marginTop: '2rem'}}>
                <FixedGrid xs={12} sm={6}>
                    <AnimatedGrid delay={200}>
                        <TiFlash size="4rem"/>
                        <Text h2>Ignite Your AI's Potential</Text>
                        <Text size={20}>Launch the fastest AI pipeline in the tech sphere with our C++ Engine. Experience the pinnacle of speed and unwavering stability for your AI applications. Elevate your AI's performance, and let our C++ Engine be the thrust behind your breakthrough.</Text>
                        </AnimatedGrid>
                </FixedGrid>
                <RightOrderGrid xs={12} sm={6}>
                    <CenteredContent><Text size={140}>🚀</Text></CenteredContent>
                </RightOrderGrid>
            </Grid.Container>
            {/*<Grid.Container gap={2} css={{marginTop: '2rem'}}>*/}
            {/*    <FixedGrid xs={12} sm={6}>*/}
            {/*        <AnimatedGrid delay={200}>*/}
            {/*            <AiOutlineBuild size="4rem"/>*/}
            {/*            <Text h2>Build with simplicity</Text>*/}
            {/*            <Text size={20}>Our low-code WebUI constructor enables rapid development, turning your*/}
            {/*                vision into reality with intuitive design tools.</Text>*/}
            {/*            </AnimatedGrid>*/}
            {/*    </FixedGrid>*/}
            {/*    <RightOrderGrid xs={12} sm={6}>*/}
            {/*        <ImageCard imageName={'graph'}/>*/}
            {/*    </RightOrderGrid>*/}
            {/*</Grid.Container>*/}
            <Grid.Container gap={2} css={{marginTop: '2rem'}} style={{alignItems: 'center'}}>
                <FixedGrid xs={12} sm={6}>
                    <AnimatedGrid delay={400}>
                        <AiOutlineCheckCircle size="4rem"/>
                        <Text h2>Maximize Your Inference Efficiency</Text>
                        <Text size={20}>Choose the best hardware for your AI with ease. Test your AI pipeline on a variety of platforms and select the hardware that delivers speed and accuracy for your needs. Get ahead - pick the power that fits your AI.</Text>
                    </AnimatedGrid>
                </FixedGrid>
                <RightOrderGrid xs={12} sm={6}>
                    <HardwareCompare/>
                </RightOrderGrid>
            </Grid.Container>
            <Spacer y={2}/>
            <AnimatedGrid delay={200}>
                <CenteredGrid>
                    <MdOutlineAutoGraph size="4rem"/>
                    <Text h2>Your AI, Optimally Launched</Text>
                    <Text size={20}>Simplify your AI launch with our straightforward service. We guide you to the best hardware and software matches from the vast tech landscape, ensuring your AI runs optimally without the hassle. Trust us to find your AI's best path forward – efficiently and effectively.</Text>
                    <Spacer y={2}/>
                    <Grid.Container justify={"center"}>
                        <Grid xs={12} md={10} lg={10}>
                            <img width={"100%"} src={networksImage} alt={'Networks'}/>
                        </Grid>
                    </Grid.Container>
                </CenteredGrid>
            </AnimatedGrid>
            <Spacer y={2}/>
            <AnimatedGrid delay={200}>
                <CenteredGrid>
                    <AiOutlineBarChart size="4rem"/>
                    <Text h2>Scale without constraints</Text>
                    <Text size={20}>Our intelligent cluster autoscaling ensures your operations can grow seamlessly
                        alongside your business.</Text>
                </CenteredGrid>
                <AutoscalerChart/>
            </AnimatedGrid>
            <Spacer y={4}/>
        </Container>
    );
}

export default Home;
