import React, { useState, useEffect } from 'react';
import { Loading } from "@nextui-org/react";
import { Row, Col, Image } from "react-bootstrap";
import { getProjectInfo, updateDeploymentInfo } from "../../../../../api/projects";
import Slider from "../../../../../utils/slider";
import { toastError, toastSuccess } from "../../../../../utils/toasts";
import { CenteredContent } from "../../ProjectMainPage";
import styles from "./styles/DeploySettings.module.css";

const DeploySettings = ({
    username,
    projectInfo,
    setProjectInfo,
    availableHardware,
    projectDeploymentInfo,
    widthScreen,
    BREAKPOINT,
}) => {
    const [newProjectInfo, setNewProjectInfo] = useState(projectInfo);
    const [maxPodsQuota, setMaxPodsQuota] = useState(
        projectInfo.is_scalable !== null ? projectInfo.is_scalable ? projectInfo.max_pods_quota : 1 : 1
    );
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    function Btn() {
        return (
            <button className={styles.button} onClick={handleSubmit} disabled={!isFormChanged || isSaving}>
                {isSaving ? <Loading type="points" size="sm" color="currentColor" /> : 'Save'}
            </button>
        )
    }

    useEffect(() => {
        if (availableHardware && availableHardware.length > 0) {
            const hardware = availableHardware.find(h => h.id.toString() === newProjectInfo.preferred_accelerator.toString());
            const isHardwareScalable = hardware ? hardware.is_scalable : false;
            setMaxPodsQuota(isHardwareScalable ? projectInfo.max_pods_quota : 1);
            setIsDataLoaded(true);
        }
    }, [newProjectInfo.preferred_accelerator, availableHardware, projectInfo.max_pods_quota]);


    useEffect(() => {
        setIsFormChanged(JSON.stringify(projectInfo) !== JSON.stringify(newProjectInfo));
    }, [newProjectInfo, projectInfo]);

    const handleSliderChange = (value, type) => {
        setNewProjectInfo(prevState => {
            const newValues = { ...prevState };
            if (type === 'min_pods') {
                newValues.min_pods = Math.min(value, prevState.max_pods);
            } else if (type === 'max_pods') {
                newValues.max_pods = Math.max(value, prevState.min_pods);
            }
            return newValues;
        });
    };

    const buildDeploymentInfo = () => {
        return {
            preferred_accelerator: newProjectInfo.preferred_accelerator,
            // region: newProjectInfo.region,
            min_pods: newProjectInfo.min_pods,
            max_pods: newProjectInfo.max_pods,
        };
    };

    const handleSubmit = async () => {
        setIsSaving(true);
        try {
            await updateDeploymentInfo(username, projectInfo.name, buildDeploymentInfo());
            const updatedProject = await getProjectInfo(username, projectInfo.name);
            setProjectInfo(updatedProject);
            setNewProjectInfo(updatedProject);
            toastSuccess('Settings updated successfully');
        } catch (error) {
            toastError(error.detail || 'An unknown error occurred');
        }
        setIsSaving(false);
    };

    const canChangeAccelerator = projectDeploymentInfo.deployments.length === 0 && newProjectInfo.max_pods === 0 && newProjectInfo.min_pods === 0;
    return (
        <div>
            <Row>
                <Col style={{ display: "flex", justifyContent: "space-between" }}>
                    {widthScreen >= BREAKPOINT &&
                        <>
                            <span className={styles.settingLabel}>Settings</span>
                            <Btn />
                        </>
                    }
                </Col>
            </Row>
            {isDataLoaded ? (
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}>
                    <Row>
                        <Col className={styles.label}>Preferred Accelerator:</Col>
                        <Col className={styles.label}>Region:</Col>
                    </Row>
                    <Row>
                        <Col className={styles.label}>
                            <select
                                className={canChangeAccelerator ? styles.select : styles.selectDisabled}
                                value={newProjectInfo.preferred_accelerator}
                                onChange={e => canChangeAccelerator && setNewProjectInfo({
                                    ...newProjectInfo,
                                    preferred_accelerator: e.target.value
                                })}
                                disabled={!canChangeAccelerator}
                            >
                                {availableHardware.map((accelerator) => (
                                    <option key={accelerator.id} value={accelerator.id}>
                                        {accelerator.label}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col className={styles.label}>
                            <select
                                className={canChangeAccelerator ? styles.select : styles.selectDisabled}
                                value={newProjectInfo.region || 'us-cheapest'}
                                onChange={e => setNewProjectInfo({
                                    ...newProjectInfo,
                                    region: e.target.value
                                })}
                                disabled={!canChangeAccelerator}
                            >
                                <option value="us-cheapest">US - Cheapest</option>
                            </select>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "24px" }}>
                        <Col style={{ display: "flex", justifyContent: "space-between" }}>
                            <span className={styles.label}>
                                Minimum Pods:
                            </span>
                            <span className={styles.minMaxValue}>
                                {newProjectInfo.min_pods} / {newProjectInfo.max_pods}
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Slider
                                min={0}
                                max={maxPodsQuota}
                                value={newProjectInfo.min_pods}
                                onChange={(min_value) => handleSliderChange(min_value, 'min_pods')}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "24px" }}>
                        <Col style={{ display: "flex", justifyContent: "space-between" }}>
                            <span className={styles.label}>
                                Maximum Pods:
                            </span>
                            <span className={styles.minMaxValue}>
                                {newProjectInfo.max_pods} / {maxPodsQuota}
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Slider
                                min={0}
                                max={maxPodsQuota}
                                value={newProjectInfo.max_pods}
                                onChange={(max_value) => handleSliderChange(max_value, 'max_pods')}
                            />
                        </Col>
                    </Row>
                    {widthScreen < BREAKPOINT &&
                        <Row className="pt-4">
                            <Col>
                                <Btn />
                            </Col>
                        </Row>
                    }
                </form>
            ) : (
                <CenteredContent>
                    <Loading size="lg" />
                </CenteredContent>
            )}
        </div>
    );
};

export default DeploySettings;