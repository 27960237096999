import styled from "styled-components";
import { Card, Text } from '@nextui-org/react';

const PlaceholderCard = styled(Card)`
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    overflow: hidden;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
        animation: pulse 2s infinite linear;
    }

    @keyframes pulse {
        from {
            left: -150%;
        }
        to {
            left: 100%;
        }
    }
`;

export const ProjectPlaceholder = () => (
    <PlaceholderCard variant="bordered">
        <Text>Loading...</Text>
    </PlaceholderCard>
);
