import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getDemos } from '../../api/demos';
import { Row, Col, Spinner, Container } from 'react-bootstrap';
import { HeaderSpacer } from '../Header/HeaderSpacer';
import Search from '../menu/Search';
import { CenteredContent } from "../Projects/ProjectMain/ProjectMainPage";
import AdaptiveDemoListItem from "../Home/AdaptiveProjectItem";
import FastSpeed from '../menu/FastSpeed';
import Card from '../Card';
import { Spacer } from '@nextui-org/react';

function DemoList() {
    const [demos, setDemos] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();
    const loadingRef = useRef(false);
    const [searchText, setSearchText] = useState('');

    const fetchData = async (page) => {
        if (loadingRef.current) return;
        setLoading(true);
        loadingRef.current = true;

        try {
            const data = await getDemos(page);
            setDemos(prev => [...prev, ...data.results]);
            setHasMore(Boolean(data.next));
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
            loadingRef.current = false;
        }
    };

    useEffect(() => {
        if (searchText) {
            setDemos(
                demos.filter(
                    e => e.name.toLowerCase().includes(
                        searchText.toLowerCase())))
        } else {
            fetchData(page);
        }
    }, [page, searchText]);

    const loadMoreDemos = useCallback(() => {
        if (hasMore && !loadingRef.current) {
            setPage(prevPage => prevPage + 1);
        }
    }, [hasMore]);

    useEffect(() => {
        fetchData(page);
    }, [page]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        };

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                loadMoreDemos();
            }
        }, options);

        return () => {
            if (observer.current) observer.current.disconnect();
        };
    }, [loadMoreDemos]);

    const lastDemoRef = useCallback(node => {
        if (observer.current) observer.current.disconnect();
        if (node) observer.current.observe(node);
    }, []);

    if (error) {
        return <div className="error">{error}</div>;
    }

    return (
        <Container fluid="lg" style={{minHeight: "calc(100vh - 190px)", marginTop: "0px"}}>
            <HeaderSpacer />
            <Row>
                <Col xs={12} md={8} lg={10}>
                    <h2>Explore model</h2>
                </Col>
                <Col>
                </Col>
            </Row>
            <Row>
                <Col xxl={3} lg={3} md={3}>
                    <Row className='mt-4 pt-1 px-2'>
                        <Col xs={12} xxl={12}>
                            <Search function={setSearchText} />
                        </Col>
                        <Col xs={12} xxl={12}>
                            <FastSpeed />
                        </Col>
                        <Col xs={12} xxl={12} className='p-0 ms-0'>
                            <hr />
                        </Col>
                        <Col xs={12} xxl={12}>
                            {/*Menu */}
                        </Col>
                    </Row>
                </Col>
                <Col lg={9} md={9}>
                    <Row>
                        {demos.map((demo, index) => (
                            <Col key={index} xs={12} sm={6} lg={6} xl={6}
                                ref={demos.length === index + 1 ? lastDemoRef : null}
                                className='pt-3'>
                                <Card props={demo} />
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
            {loading && (
                <CenteredContent>
                    <Spinner animation="border" role="status" variant="secondary">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </CenteredContent>
            )}
            <HeaderSpacer />
        </Container>
        //<Container lg={true}>
        //    <HeaderSpacer/>
        //    <Grid.Container>
        //        <Grid xs={8}>
        //            <Text h2>Choose any Neural Network and Run in one click</Text>
        //        </Grid>
        //    </Grid.Container>
        //    <Grid.Container gap={2}>
        //        {demos.map((demo, index) => (
        //            <Grid key={demo.id} xs={12} sm={6} md={6} lg={6} xl={6}
        //                  ref={demos.length === index + 1 ? lastDemoRef : null}>
        //                <AdaptiveDemoListItem project={demo}/>
        //            </Grid>
        //        ))}
        //    </Grid.Container>
        //    {loading && <CenteredContent><Loading color={"secondary"}/></CenteredContent>}
        //</Container>
    );
}

export default DemoList;
