import axiosInstance, {axiosGatewayInstance} from "./request";

const projectUrl = "/";

export async function getProjects(username, page=1, pageSize=12) {
    return await axiosInstance.get(`${projectUrl}${username}/?page=${page}&page_size=${pageSize}`);
}

export async function getProjectInfo(username, projectName) {
    return await axiosInstance.get(`${projectUrl}${username}/${projectName}/`);
}

export async function getProjectState(username, projectName) {
    return await axiosInstance.get(`${projectUrl}${username}/${projectName}/state/`);
}

export async function getInfStatisticsPerAccelerator(username, projectName) {
    return await axiosInstance.get(`${projectUrl}${username}/${projectName}/get_inf_statistics_per_accelerator/`);
}

export async function createProject(username, project) {
    return await axiosInstance.post(`${projectUrl}${username}/`, project);
}

export async function updateProjectInfo(username, projectName, project) {
    return await axiosInstance.patch(`${projectUrl}${username}/${projectName}/`, project);
}

export async function updateDeploymentInfo(username, projectName, deploymentInfo) {
    return await axiosInstance.put(`${projectUrl}${username}/${projectName}/update_deployment_settings/`, deploymentInfo);
}

export async function updateProjectHardware(username, projectName, hardware) {
    return await axiosInstance.patch(`${projectUrl}${username}/${projectName}/hardware/`, hardware);
}

export async function checkProjectName(username, newProjectName, currentProjectName=null) {
    const projectData = {
        "new_project_name": newProjectName,
        "current_project_name": currentProjectName
    }
    return await axiosInstance.post(`${projectUrl}${username}/check_project_name/`, projectData);
}

export async function cloneProject(username, projectName, requestUserName) {
    return await axiosInstance.post(`${projectUrl}${username}/${projectName}/clone/`, {target_owner_username: requestUserName});
}

export async function deleteProject(username, projectName) {
    return await axiosInstance.delete(`${projectUrl}${username}/${projectName}/`)
}

export async function getHardwareData(username, projectName, page=1) {
    return await axiosInstance.get(`${projectUrl}${username}/${projectName}/hardware/?page=${page}`);
}

export async function getLaunchInferenceInfo(username, projectName, hardwareId) {
    return await axiosInstance.get(`${projectUrl}${username}/${projectName}/launch-inference-info/?hardware_id=${hardwareId}`);
}

export async function getStartupLogs(username, projectName, instanceId) {
    return await axiosInstance.get(`${projectUrl}${username}/${projectName}/logs/${instanceId}/`);
}

export async function getProjectPerformanceResults(username, projectName) {
    return await axiosInstance.get(`${projectUrl}${username}/${projectName}/performance-results/`);
}

export async function getLaunchLogs(instanceId) {
    return await axiosInstance.get(`${projectUrl}launch-logs/${instanceId}/`);
}

export async function testCPN(username, projectName, nodeID) {
    return await axiosInstance.post(`${projectUrl}${username}/${projectName}/nodes/${nodeID}/test_cpn/`);
}

export async function checkCeleryTaskStatus(taskId) {
    return await axiosInstance.get(`${projectUrl}check-celery-task/${taskId}/`);
}

export async function getCPNTestResults(username, projectName, nodeID) {
    return await axiosInstance.get(`${projectUrl}${username}/${projectName}/nodes/${nodeID}/get_cpn_test_result/`);
}

export async function changeProjectWorkingMode(username, projectName, workingMode) {
    return await axiosInstance.put(`${projectUrl}${username}/${projectName}/change_working_mode/`, workingMode);
}

export async function getCloudAcceleratorList(username, projectName) {
    return axiosInstance.get(`${projectUrl}${username}/${projectName}/get_available_accelerators/`);
}
