import {Card, Text, Button, Row} from '@nextui-org/react';
import {Link as LK} from 'react-router-dom';
import React from 'react';
import styled from "styled-components";
import flashBgPurple from './images/flashBgPurple.svg';
import flashBgGrey from './images/flashBgGrey.svg';
import {IoIosRocket} from "react-icons/io";
import {FaBolt} from "react-icons/fa6";
import {FaBullseye} from "react-icons/fa";
const StyledCard = styled(Card)`
    display: grid;
    gap: 0;
    width: 100%;
    height: 250px;
    grid-template-columns: ${({imageUrl}) => imageUrl ? '250px 1fr' : '1fr'};
    grid-template-areas: ${({imageUrl}) => imageUrl ? "'image content'" : "'content'"};

    @media (max-width: 576px) {
        grid-template-columns: 1fr;
        grid-template-rows: ${({imageUrl}) => imageUrl ? 'auto 1fr auto' : '1fr auto'};
        height: auto;
        grid-template-areas: ${({imageUrl}) => imageUrl ? "'image' 'content' 'footer'" : "'content' 'footer'"};
    }
`;

const ImageWrapper = styled.div`
    grid-area: image;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    @media (max-width: 576px) {
        width: 100%;
        height: ${({ imageUrl }) => imageUrl ? '0' : 'auto'};
        padding-top: ${({ imageUrl }) => imageUrl ? '100%' : '0'};
        position: relative;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const ContentWrapper = styled.div`
    grid-area: content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 16px;
    background-image: ${({isOptimized}) => isOptimized ? `url(${flashBgPurple})` : 'none'};
    background-position: center;
    background-size: 400%;


    @media (max-width: 576px) {
        padding: 16px;
        flex-grow: 1;
        justify-content: space-between;
    }
`;

const TitleText = styled(Text)`
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
    margin-top: 8px;
    font-family: 'SF Mono', 'Menlo', 'Monaco', 'Consolas', 'Courier New', monospace;
`;

const DescriptionText = styled(Text)`
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    font-size: 14px;
    flex-grow: 1;
    line-height: 1.6em;
    white-space: normal;
    height: calc(1.6em * 6);
    margin-bottom: 26px;
`;



const AdaptiveDemoListItem = ({project}) => {
    if (!project.cover_image) {
        project.cover_image = flashBgGrey;
    }
    if (project.isPlaceholder) {
        return (
            <LK to={`/request-new-model`} style={{width: "100%"}}>
                <StyledCard variant="bordered">
                    <ContentWrapper>
                        <TitleText b>{project.name}</TitleText>
                        <DescriptionText style={{"fontSize": "16px"}}>{project.description}</DescriptionText>
                    </ContentWrapper>
                </StyledCard>
            </LK>
        );
    }
    return (
        <LK to={`/${project.owner}/${project.name}`} style={{width: "100%"}}>
            <StyledCard variant="bordered" imageUrl={project.cover_image} >
                <ImageWrapper imageUrl={project.cover_image}>
                    <img src={project.cover_image} alt={`${project.name} image`} />
                </ImageWrapper>
                <ContentWrapper isOptimized={project.is_optimized}>
                    <TitleText b>{project.owner}/{project.name}</TitleText>
                    <DescriptionText>{project.description}</DescriptionText>
                    <Card.Footer style={{width: "100%"}}>
                        <Row justify={"space-between"}>
                            <Text>
                                {project.state ? (
                                    <> <FaBullseye style={{ verticalAlign: 'text-bottom' }} /> State: {project.state}</>
                                ) : project.is_optimized ? (
                                    <> <FaBolt style={{ verticalAlign: 'text-bottom' }} /> World's Fastest </>
                                ) : null}
                            </Text>
                            <Text><IoIosRocket style={{ verticalAlign: 'text-bottom' }}/> {project.predictions_count || 0} Runs</Text>
                        </Row>

                    </Card.Footer>
                </ContentWrapper>
            </StyledCard>
        </LK>
    );
};

export default AdaptiveDemoListItem;
