import { Stack, Image } from "react-bootstrap";
import commercial from "./images/commercial.svg";
import research from "./images/research.svg";
import runs from "./images/runs.svg";
import asselerated from "./images/asselerated.svg";
import styles from "./Tags.module.css";

export default function Tags({ props }) {
    const { has_commercial_license, predictions_count, is_optimized } = props;
    return (
        <div className={styles.badgeContainer}>
            {has_commercial_license ?
                <Badge
                    text="For commercial use"
                    style={{ background: "#EBF7BA" }}
                    image={commercial} />
                :
                <Badge
                    text="For research only"
                    style={{ background: "#C5E0FE" }}
                    image={research} />
            }
            {predictions_count &&
                <Badge
                    text={`Runs ${predictions_count}`}
                    style={{ background: "#FFEFE7" }}
                    image={runs}
                />}
            {is_optimized &&
                <Badge
                    text="Accelerated by FMA"
                    style={{
                        background: "linear-gradient(#1A67C1, #0F32AF)",
                        color: "white"
                    }}
                    image={asselerated} />}
        </div>
    );
}

function Badge({ text, style, image }) {
    return (
        <div className={styles.main} style={{ ...style }}>
            <Image src={image} alt="image" />
            {text}
        </div>
    );
}