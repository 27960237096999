import React, {Component, useEffect} from "react";
import Home from './components/Home/Home';
import ProjectList from './components/Projects/ProjectList';
import ProjectDetail from './components/Projects/ProjectDetail';
import LoginForm from './components/Auth/LoginForm';
import Logout from './components/Auth/Logout';
import { NextUIProvider} from '@nextui-org/react';
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react';
import {store, persistor} from './store/store';
import RegisterForm from "./components/Auth/RegisterForm";
import ProfileSettings from "./components/Profile/Profile";
import './index.css';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import DemoList from "./components/Demos/DemoList";
import {OAuth2RedirectHandler} from "./components/Auth/OAuth2RedirectHandler";
import FinancesList from "./components/Finances/FinancesList";
import * as Sentry from "@sentry/react";
import Welcome from "./pages/Welcome";
import ProjectMainPage from "./components/Projects/ProjectMain/ProjectMainPage";
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation} from 'react-router-dom';
import PrivateRoute from './utils/privateRoute';
import ReactGA from "react-ga4";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/Privacy";
import LegacyLoginForm from "./components/Auth/LegacyLoginForm";
import Header from "./components/Header";
import Footer from "./components/Footer";
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);


Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router>
                        <NextUIProvider>
                            <Header />
                            <Routes>
                                <Route path="/legacy-login" element={<LegacyLoginForm />} />
                                <Route path="/login" element={<LoginForm />} />
                                <Route path="/terms" element={<Terms />} />
                                <Route path="/privacy" element={<PrivacyPolicy />} />
                                <Route path="/signup" element={<RegisterForm />} />
                                <Route path="/auth/callback/:service" element={<OAuth2RedirectHandler />} />
                                <Route path="/welcome" element={<Welcome />} />
                                <Route
                                    exact
                                    path="/"
                                    element={<PrivateRoute element={Home} />}
                                />
                                <Route
                                    path="/logout"
                                    element={<PrivateRoute element={Logout} />}
                                />
                                <Route
                                    path="/explore"
                                    element={<PrivateRoute element={DemoList} />}
                                />
                                <Route
                                    path="/profile"
                                    element={<PrivateRoute element={ProfileSettings} />}
                                />
                                <Route
                                    path="/finances"
                                    element={<PrivateRoute element={FinancesList} />}
                                />
                                <Route
                                    path="/:username"
                                    element={<PrivateRoute element={ProjectList} />}
                                />
                                <Route
                                    path="/:username/:projectName/graph"
                                    element={<PrivateRoute element={ProjectDetail} />}
                                />
                                <Route
                                    path="/:username/:projectName"
                                    element={<PrivateRoute element={ProjectMainPage} />}
                                />
                                <Route path="*" element={<Navigate to="/" />} />
                            </Routes>
                            <Footer />
                        </NextUIProvider>
                        <ToastContainer limit={5} />
                    </Router>
                </PersistGate>
            </Provider>
        );
    }
}
export default App;