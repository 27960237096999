import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { Spacer, Text } from "@nextui-org/react";
import styles from "./styles/DeploySettings.module.css";
import DeploymentInfo from "./PodCard";
import Union from "./images/Union.svg";

export default function PodsContainer({ projectDeploymentInfo, projectInfo, widthScreen, BREAKPOINT, ...props }) {
    const totalCost = projectDeploymentInfo.deployments.reduce((acc, deployment) => {
        acc += deployment.hourly_price;
        return acc;
    }, 0);

    projectDeploymentInfo.deployments.sort((a, b) => {
        const states = ['RUNNING', 'STARTING', 'STOPPING'];
        const stateIndexA = states.indexOf(a.state);
        const stateIndexB = states.indexOf(b.state);

        if (stateIndexA < stateIndexB) return -1;
        if (stateIndexA > stateIndexB) return 1;

        return 0;
    });

const activePods = projectDeploymentInfo.deployments.length;
const potentialPods = projectInfo.max_pods - activePods;

function Btn() {
    return (
        <button
            className={styles.refresh}
            onClick={_ => props.updateProjectState()}
        >
            Refresh <Image src={Union} alt="Union" height={14} style={{ paddingBottom: "2px" }} />
        </button>
    );
}

return (
    <>
        <Row>
            <Col>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ lineHeight: "36px" }}>
                        <span className={styles.settingLabel}>
                            Monitoring
                        </span>
                    </div>
                    {widthScreen >= BREAKPOINT &&
                        <Btn />
                    }
                </div>
            </Col>
        </Row>
        <Row style={{ paddingTop: "12px" }}>
            <Col className={styles.label} style={{ paddingTop: "4px" }}>
                Active Pods:&nbsp;
                <span className={styles.podsCount}>
                    {activePods}/{projectInfo.max_pods}
                </span>
            </Col>
        </Row>
        <Row>
            <Col className={styles.activePods} xs={12} >
                {console.log(projectDeploymentInfo.deployments)}
                {projectDeploymentInfo.deployments.map((deployment, index) => (
                    <DeploymentInfo key={index} deployment={deployment} />
                ))}
            </Col>
        </Row>
        <Spacer y={1} />
        <Row justify={"space-between"}>
            <Text className={styles.label} style={{marginBottom: "0"}}>Idle Pods: <span
                className={styles.podsCount}>{potentialPods}</span></Text>
        </Row>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
            {Array.from({ length: potentialPods }, (_, index) => (
                <DeploymentInfo key={`potential-${index}`} deployment={{ state: "IDLE" }} />
            ))}
        </div>
        {widthScreen < BREAKPOINT &&
            <Row className="pt-4">
                <Col>
                    <Btn />
                </Col>
            </Row>
        }
    </>
);
}
